:root {
  --black: #262626;
  --ee-dark-blue: #21314a;
  --is-blue: #1369e3;
  --dim-grey-shadow: #73737d;
  --is-light-blue: #f2f7fc;
  --is-light-grey: #f8f8f8;
  --white: white;
  --hover-blue: #283f5f;
  --card_blue: #e5eef9;
  --is-radio-input: #d4d4d8;
  --input-background: white;
  --is-red: red;
  --form-explain-question: #777780;
  --accessible-components-dark-grey: #9b9b9b;
  --transparent: #fff0;
  --brand: var(--is-blue);
  --is-dark-blue: #004ab4;
  --is-grey-v2: #7d7d7d;
  --accessible-components-dodger-blue: #1378d1;
  --blog-blue: #002458;
  --gray: red;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: ' ';
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: ' ';
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('../../marketing-scripts/webflow/custom-checkbox-checkmark.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

body {
  color: var(--black);
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
}

h1 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-family: Poppins, sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
}

h2 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-family: Poppins, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
}

h3 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
}

h4 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.875rem;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

h6 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.3125rem;
}

p {
  margin-bottom: 1.25rem;
  font-family: Poppins, sans-serif;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

a {
  text-decoration: underline;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

li {
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

label {
  margin-bottom: 0;
  font-weight: 700;
  display: block;
}

.heading {
  min-width: 500px;
  font-family: Roboto, sans-serif;
  font-size: 48px;
  line-height: 50px;
}

.heading.centertext {
  text-align: center;
}

.navbar {
  background-color: #f5f5f5;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: sticky;
}

.nav-link {
  color: #1369e3;
  font-family: Roboto, sans-serif;
}

.text-block {
  text-align: center;
  max-width: 450px;
  margin-top: 2px;
  margin-bottom: 14px;
  font-size: 20px;
  line-height: 24px;
}

.text-block.header {
  text-align: left;
}

.button {
  background-color: #1369e3;
  border-radius: 8px;
  padding: 14px 24px;
  font-size: 20px;
  line-height: 24px;
}

.button.primary {
  margin-right: 23px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  box-shadow: 1px 1px 3px #5e5e5e;
}

.button.primary.margin-top {
  margin-top: 1rem;
}

.button.inverse {
  color: #1468e3;
  background-color: #fff;
  border: 1px solid #1468e3;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  box-shadow: 1px 1px 3px #818181;
}

.button.cm-secondary {
  color: #000;
  background-color: #f7f8fa;
  margin-top: 5px;
  line-height: 30px;
  box-shadow: inset 0 0 0 2px #000;
}

.button.cm-primary {
  line-height: 30px;
}

.button.cc-allow {
  color: #fff;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 52px;
  line-height: 10px;
  display: flex;
}

.button.cc-allow:hover {
  background-color: #283f5f;
}

.button.cc-deny {
  color: #1369e3;
  cursor: pointer;
  background-color: #0000;
  border: 3px solid #1369e3;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 52px;
  line-height: 10px;
  display: flex;
}

.button.cc-deny:hover {
  color: #fff;
  background-color: #283f5f;
  border-color: #283f5f;
}

.feature-section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.feature-section.blue {
  background-color: #1369e340;
}

.feature-section.darkblue {
  background-color: #20314a;
}

.flex-container {
  flex-direction: row;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  max-width: 90%;
  display: flex;
}

.flex-container.spacebetween {
  justify-content: space-between;
  display: flex;
}

.flex-container.vertical {
  flex-direction: column;
}

.hero {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 48px;
  padding-top: 30px;
  padding-bottom: 60px;
  display: flex;
}

.heading2 {
  text-align: center;
  max-width: 500px;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  line-height: 40px;
}

.heading2.white {
  color: #f7f8fa;
}

.row {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}

.row.spaced-row.card {
  width: 100%;
}

.row.space-around {
  justify-content: space-around;
}

.text {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  line-height: 24px;
}

.features {
  padding-top: 4px;
  padding-bottom: 18px;
}

.landing_stars {
  width: 12.5rem;
}

.landing_stars.five-star {
  width: 15rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.landing_stars.five-star.is-mobile {
  opacity: 0.74;
  width: 12.5rem;
  margin-top: 2.2rem;
  margin-bottom: 0.5rem;
  display: block;
}

.landing_stars.five-star.is-mobile.industry-specific {
  width: 8rem;
  margin-top: 0.5rem;
}

.landing_stars.is-hero {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

.proof_profile-name {
  color: #797979;
  margin-left: 10px;
}

.proof_profile-wrapper {
  margin-top: 13px;
  display: flex;
}

.proof_profile-icon {
  border-radius: 200px;
  max-width: 59px;
}

.header-div {
  flex-direction: row;
  flex: none;
  justify-content: flex-start;
  align-self: auto;
  align-items: center;
  display: flex;
}

.brand {
  align-self: center;
}

.navbutton {
  color: #1369e3;
  background-color: #fff;
  border: 2px solid #1369e3;
  border-radius: 8px;
  margin: 8px;
  padding: 12px 20px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

.div_footer {
  background-color: var(--ee-dark-blue);
  justify-content: center;
  margin-top: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
}

.div_footer.industry {
  padding-top: 3rem;
}

.container-footer {
  grid-row-gap: 0.5rem;
  color: #acacac;
  flex-direction: column;
  align-items: center;
  width: 80%;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
}

.lightgreytext {
  color: #acacac;
}

.lightgreytext.manage-cookies {
  text-decoration: underline;
}

.lightgreytext.hyphen {
  padding-left: 5px;
  padding-right: 5px;
}

.lightgreytext.copyright {
  margin-top: 10px;
}

.stackedcards {
  flex: 0 auto;
  width: auto;
  max-width: 35%;
  display: block;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.text-block-6 {
  font-size: 64px;
  line-height: 90px;
}

.featureheader {
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
}

.smallfeaturediv {
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

.super-large-text {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Roboto, sans-serif;
  font-size: 150px;
  font-weight: 900;
  line-height: 160px;
}

.subtext-large {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  line-height: 24px;
}

.large-text-container {
  width: 50%;
}

.div-block-12 {
  width: 10%;
}

.div-block-13 {
  width: 60px;
  height: 0;
  padding-right: 0;
}

.feature-heading {
  text-align: left;
  max-width: 500px;
  font-family: Roboto, sans-serif;
  font-size: 36px;
}

.feature-text-block {
  text-align: left;
  max-width: 450px;
  margin-top: 2px;
  margin-bottom: 14px;
  font-size: 20px;
  line-height: 24px;
}

.stars {
  font-size: 32px;
  line-height: 32px;
}

.modal-background {
  z-index: 9000;
  object-fit: cover;
  background-color: #00000080;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  display: block;
  position: fixed;
  inset: 0%;
}

.modal-container {
  z-index: 9001;
  background-color: #fff;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  width: 450px;
  max-width: 100%;
  margin: 80px auto auto;
  padding: 20px;
  display: flex;
  position: fixed;
  inset: 0% 0% auto;
}

.image-16 {
  max-width: 25%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.form-block {
  justify-content: center;
  width: 100%;
  display: block;
}

.form {
  flex-direction: column;
  display: flex;
}

.textfield {
  color: #2b2b2b;
  background-color: #fff;
  border-radius: 6px;
  height: 48px;
  margin-bottom: 27px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.field-label {
  color: #8f8f8f;
  margin-top: 18px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.modal {
  display: none;
}

.modal.modal-background {
  display: flex;
}

.modal.modal-container {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 450px;
  max-width: 90%;
  display: flex;
}

.success-message {
  background-color: #dbeaff;
  border-radius: 12px;
}

.text-block-8 {
  margin-top: 11px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
}

.download-button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 48px;
  display: flex;
}

.download-button._1-5x {
  width: 216px;
  height: 72px;
}

.download-button._1-25x {
  width: 180px;
  height: 60px;
}

.spacer {
  width: 32px;
  height: 32px;
}

.spacer.is-socialproof {
  background-color: #f8f8f8;
  width: 100%;
}

.spacer.phone-only {
  display: none;
}

.h1 {
  font-family: Roboto, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
}

.googleplay {
  width: 150px;
}

.applebutton {
  width: 140px;
}

.textsection {
  align-self: center;
  max-width: 75rem;
  padding: 1.5rem;
}

.whitetext {
  color: #f7f8fa;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 22px;
  display: flex;
}

.greytext {
  color: #8d8d8d;
  font-size: 16px;
}

.greytext.flex-center {
  text-align: center;
}

.greytext.text-style-light.industry-header {
  margin-top: 38px;
  margin-bottom: 0;
}

.link1 {
  font-size: 16px;
  line-height: 30px;
}

.gridexpense {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 3px solid #bababa;
  border-radius: 10px;
  flex: 1;
  grid-template:
    '. Area'
    / 0.25fr 1.75fr;
  grid-auto-flow: row;
  align-self: stretch;
  min-height: 100px;
  margin-bottom: 20px;
  padding: 10px 0;
  box-shadow: 2px 2px 3px #ddd;
}

.gridheading {
  flex-direction: row;
  align-items: baseline;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.gridicon {
  -webkit-text-fill-color: inherit;
  background-clip: border-box;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 50px;
  line-height: 50px;
}

.gridicon.low {
  padding-bottom: 20px;
}

.gridp1 {
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 20px;
}

.adcontainer {
  background-color: #fff;
  border: 3px solid #bababa;
  border-radius: 20px;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 800px;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 3px #ddd;
}

.blogadimage {
  -webkit-text-fill-color: inherit;
  background-clip: padding-box;
  border-radius: 20px 0 0 20px;
  max-width: 40%;
  padding-top: 0;
}

.ctalogo {
  max-width: 40%;
  margin-top: 0;
}

.image-26 {
  float: right;
  max-width: 50%;
  margin-top: 18px;
  margin-bottom: 4px;
  display: block;
}

.text-block-14 {
  color: #000;
  text-align: right;
}

.blog_cta-socialproof {
  clear: none;
  grid-row-gap: 0.25rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.adcontentdiv {
  padding-bottom: 12px;
  padding-left: 36px;
  padding-right: 0;
}

.image-27 {
  text-align: left;
  align-self: center;
  max-width: 40vw;
  max-height: 25vh;
  position: relative;
}

.card-waitlist-form {
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  display: flex;
}

.bodyflex {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.bulletlinks {
  line-height: 25px;
}

.dropdown-link {
  border: 1px solid var(--is-blue);
  background-color: #fff;
  font-size: 20px;
}

.dropdown-link:hover {
  background-color: #f2f7fc;
}

.calc-breakdown_grid-faucet-repair {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template:
    '.'
    '.'
    / 1.5fr 1fr 1fr 1fr 1fr 1.5fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  overflow: auto;
}

.calc-breakdown_grid-faucet-repair.is-faucet-repair {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.25fr;
}

.grid-number {
  text-align: center;
}

.cc-banner {
  z-index: 1000;
  width: 100%;
  display: none;
}

.cc-wrapper {
  z-index: 999;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  inset: auto 0% 0%;
  box-shadow: 0 2px 100px 7px #0003;
}

.cc-banner_emoji {
  color: #000;
  text-align: center;
  justify-content: center;
  align-self: center;
  font-size: 42px;
}

.cc-banner_button-wrapper {
  grid-row-gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cc-formblock {
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  display: flex;
  position: fixed;
  inset: 0%;
}

.cc-form {
  z-index: 1000;
  background-color: #f7f8fa;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  padding: 40px;
  display: flex;
  position: relative;
}

.checkbox-field {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-right: 12px;
}

.checkbox.w--redirected-checked {
  background-color: #02d117;
}

.cc-banner_buttondiv {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.cookie-preferences {
  z-index: 1000;
  background-color: #000000ab;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  inset: 0%;
}

.cc-banner_container {
  z-index: 1;
  clear: none;
  grid-row-gap: 1rem;
  background-color: #fff;
  flex-flow: wrap;
  flex: 0 auto;
  place-content: center space-between;
  align-self: center;
  align-items: center;
  width: 100%;
  padding: 16px;
  display: flex;
  position: relative;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-shadow: 0 0 20px 5px #0003;
}

.cc-banner_paragraph {
  color: #000;
  text-align: left;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self: center;
  margin-bottom: 0;
  padding-right: 43px;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
}

.cc-banner_link {
  color: #1369e3;
  cursor: pointer;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 16px;
  line-height: 24px;
}

.div_footer-links {
  text-align: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  display: flex;
}

.cc-footer {
  display: none;
}

.cc-banner_overlay {
  z-index: 0;
  background-color: #000000e6;
  align-items: stretch;
  display: flex;
  position: fixed;
  inset: 0%;
}

.cc-banner_label {
  font-size: 16px;
  line-height: 24px;
}

.text-span-2 {
  margin-left: -3px;
  font-size: 20px;
}

.cc-banner_text {
  margin-bottom: 20px;
  padding-bottom: 0;
}

.bold-text-3 {
  padding-left: 5px;
  font-size: 16px;
  line-height: 24px;
}

.body-flex {
  background-color: #fff;
  flex-direction: column;
  align-items: stretch;
  font-size: 16px;
  line-height: 24px;
  display: flex;
}

.h3 {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 2.5rem;
}

.h3.cookies {
  font-size: 26px;
}

.subtitle {
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: italic;
  line-height: 24px;
}

.grid-header {
  text-align: center;
  font-weight: 500;
}

.nav-button {
  color: #1369e3;
  text-align: center;
  background-color: #fff;
  border: 4px #1369e3;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 7.5rem;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.688rem;
  display: flex;
  box-shadow: 1px 1px 3px #5e5e5e;
}

.nav-button:hover {
  color: #fff;
  background-color: #283f5f;
  border-color: #283f5f;
}

.nav-button.is-blue-fill {
  background-color: var(--is-blue);
  box-shadow: 1px 1px 3px 0 var(--dim-grey-shadow);
  color: #fff;
  border-radius: 100px;
  width: 9rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
}

.nav-button.is-blue-fill:hover {
  background-color: #283f5f;
}

.nav-link {
  color: #262626;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  font-size: 1.125rem;
  line-height: 1.688rem;
  display: flex;
  position: static;
}

.nav-link:hover {
  border-bottom: 1px solid var(--is-blue);
  color: #1369e3;
}

.nav-link.w--current {
  display: flex;
}

.page-wrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.main-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.main-wrapper.align-stretch {
  display: flex;
}

.section_feature {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.section_feature.is-light-blue {
  background-color: var(--is-light-blue);
}

.section_proof {
  display: block;
}

.section_proof.is-light-grey {
  background-color: var(--is-light-grey);
}

.section_proof.is-landing {
  padding-bottom: 2rem;
}

.blog_padding-horizontal {
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
}

.container-large {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 71rem;
  margin-left: auto;
  margin-right: auto;
}

.container-large.flex-horizontal-align-center {
  flex-direction: row;
  display: flex;
}

.container-large.vertical-align-center {
  flex-direction: column;
  display: flex;
}

.landing_padding-vertical {
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.landing_padding-vertical.is-hero {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.landing_padding-vertical.is-socialproof-over {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.landing_hero-content {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 60%;
  display: flex;
}

.industry_header-2 {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 3.9rem;
  font-weight: 700;
  line-height: 4.2rem;
}

.industry_header-2.flex-center {
  text-align: center;
}

.industry_header-2.flex-center.industry {
  margin-bottom: 1.5rem;
}

.landing_text-style-p {
  max-width: 28rem;
  margin-bottom: 0;
}

.landing_text-style-p.is-subtext {
  color: #747474;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
  display: block;
}

.landing_text-style-p.is-subtext.review-text {
  margin-top: -0.9rem;
}

.landing_text-style-p.is-hero {
  text-align: left;
  max-width: 33rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  font-weight: 300;
}

.landing_text-style-p.is-hero.flex-center.industry {
  margin-bottom: 2.8rem;
}

.landing_button {
  color: var(--white);
  text-align: center;
  background-color: #1369e3;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 175px;
  padding: 12px 32px;
  font-weight: 500;
  display: flex;
  box-shadow: 1px 1px 3px #5e5e5e;
}

.landing_button:hover {
  background-color: var(--hover-blue);
}

.landing_button.is-mobile {
  display: none;
}

.landing_button.card {
  width: 200px;
}

.landing_button.dark_card {
  width: 250px;
  margin-bottom: 23px;
}

.landing_button.is-longer {
  width: 200px;
}

.landing_button.dark_card-v2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.landing_button.is-landing-hero {
  width: auto;
  min-width: 175px;
  min-height: 50px;
  max-height: 50px;
}

.landing_button.is-landing-hero.yellow {
  color: var(--black);
  background-color: #f6cb50;
}

.landing_button.for-teams {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.landing_button.is-break {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.landing_app-container {
  grid-column-gap: 1.5rem;
  grid-row-gap: 0rem;
  flex-flow: wrap;
  flex: 0 auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.img_app-store {
  cursor: pointer;
  width: 144px;
  height: 48px;
}

.img_app-store._1-5x {
  width: 216px;
  height: 72px;
}

.img_app-store._1-25x {
  width: 180px;
  height: 60px;
}

.landing_feature-image {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 50%;
  display: flex;
}

.landing_feature-image.is-hero {
  max-width: 20%;
  max-height: 80%;
}

.heading-13 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.landing_text-style-h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.landing_text-style-h2.is-socialproof {
  max-width: 40rem;
  margin-top: 0;
  margin-bottom: 0;
}

.landing_text-style-h2.industry-specific {
  max-width: 100%;
  margin-bottom: 2rem;
  font-size: 3rem;
  line-height: 4rem;
}

.landing_text-style-h2.industry-specific.flex-center {
  text-align: center;
}

.landing_list-item {
  grid-column-gap: 0.75rem;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  display: flex;
}

.landing_proof-wrapper {
  grid-row-gap: 2rem;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.landing_list-wrapper {
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.marquee_card {
  background-color: #fff;
  border-radius: 9px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 30rem;
  padding: 1rem;
  display: flex;
  box-shadow: 6px 8px 9px #0003;
}

.div_row-footer {
  grid-column-gap: 1.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;
}

.landing_feature-item {
  grid-column-gap: 2rem;
  justify-content: center;
  display: flex;
}

.landing_hero-container {
  grid-column-gap: 2rem;
  justify-content: flex-start;
  width: 100%;
  max-width: 71rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 4rem;
  display: flex;
}

.faq_item {
  border-bottom: 1px solid var(--dim-grey-shadow);
}

.section_free-tax-header {
  background-color: var(--ee-dark-blue);
  color: var(--white);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.accordion-item {
  width: 100%;
  overflow: hidden;
}

.accordion-item.is-landing {
  display: none;
}

.accordion-toggle {
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding: 1.5rem 3px 1.5rem 1.5px;
  display: flex;
}

.accordion-toggle.is-justify-center {
  justify-content: center;
}

.accordion-toggle.getting-started,
.accordion-toggle._3rem {
  height: 3rem;
}

.dropdown-content {
  background-color: #0000;
  position: static;
}

.dropdown-content.w--open {
  flex-direction: column;
  display: flex;
}

.heading-style-h3 {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.heading-style-h3.get-started {
  font-weight: 500;
}

.heading-style-h2 {
  white-space: normal;
  font-size: 2rem;
  line-height: 3rem;
}

.heading-style-h2.get-started {
  background-color: var(--card_blue);
  margin-bottom: 0;
  padding-left: 5px;
}

.global-text-align-center {
  text-align: center;
}

.global-text-align-center.bottom-margin-medium {
  margin-bottom: 2rem;
}

.about_wrapper {
  flex-direction: row;
  place-content: space-around space-between;
  display: flex;
}

.about_container {
  grid-row-gap: 1rem;
  object-fit: fill;
  flex-direction: column;
  align-items: center;
  max-width: 45%;
  display: flex;
}

.about_profile-image {
  max-width: 10rem;
}

.about_name-text {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.about_job-text {
  color: #777;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.faq_item-wrapper {
  padding-left: 0;
}

.more-features_container {
  grid-column-gap: 1rem;
  color: #1369e3;
  justify-content: center;
  font-weight: 700;
  text-decoration: underline;
  display: flex;
}

.section_feature-m {
  flex-direction: column;
  display: none;
}

.section_free-calculator {
  margin-top: -3rem;
}

.footer-link {
  color: #acacac;
  cursor: pointer;
  text-decoration: underline;
}

.footer-link.is-email {
  margin-bottom: 0.5rem;
  text-decoration: none;
  display: flex;
}

.footer-hyphen {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.marquee-fwd_container {
  grid-column-gap: 2rem;
  display: flex;
}

.marquee-fwd_container._2 {
  align-items: flex-start;
}

.marquee-back_wrapper {
  grid-column-gap: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  display: flex;
  overflow: hidden;
}

.marquee-wrapper-back {
  z-index: 200;
  width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  overflow: hidden;
}

.cc-div_text {
  align-items: center;
  display: flex;
}

.cc-div_buttons {
  grid-column-gap: 1rem;
  display: flex;
}

.marquee-fwd_wrapper {
  grid-column-gap: 1rem;
  justify-content: flex-end;
  padding-bottom: 1rem;
  display: flex;
  overflow: hidden;
}

.marquee-wrapper-fwd {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.marquee-back_container {
  grid-column-gap: 2rem;
  display: flex;
}

.marquee-back_container._2 {
  align-items: flex-end;
}

.font-black {
  color: #262626;
  cursor: pointer;
}

.calc_progress-container {
  background-color: var(--is-light-blue);
  text-align: center;
  flex-direction: column;
  padding: 2rem;
  text-decoration: none;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2px 5px 3px #b9dcff;
}

.calc_wrapper {
  grid-column-gap: 3rem;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5rem;
  display: flex;
}

.calc_content-container {
  flex-direction: column;
  justify-content: space-between;
  min-width: 35%;
  max-width: 35%;
  display: flex;
}

.calc_slider {
  background-color: #0000;
  height: 100%;
  overflow: hidden;
  box-shadow: 1px 1px 5px 3px #0003;
}

.next-arrow {
  flex-direction: column;
  align-items: stretch;
  width: 125px;
  display: flex;
  inset: auto 0% 10% 50%;
}

.calc_slider-slide {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.bg-white-smoke {
  background-color: #f5f5f5;
}

.calc-form_radio-input {
  border: 1px solid var(--is-radio-input);
  background-color: var(--input-background);
  cursor: pointer;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  box-shadow: 1px 1px 3px 1px #0003;
}

.hidden {
  display: none;
}

.calc_form-question-header {
  text-align: center;
  font-weight: 700;
}

.calc_form-question-header.is-title {
  font-size: 2rem;
  line-height: 2.5rem;
  display: none;
}

.calc_form-question-wrapper {
  grid-row-gap: 1.5rem;
  flex-direction: column;
  display: flex;
}

.calc_form-question-wrapper.is-industry {
  margin-top: 10%;
}

.calc_form-select-container {
  grid-row-gap: 1rem;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.calc_form-input-field-container {
  grid-column-gap: 0.5rem;
  align-items: center;
  width: 40%;
  display: flex;
}

.calc_form-input-field-container.is-calc-result {
  width: 100%;
}

.input_field {
  border-radius: 10px;
  width: 7rem;
  height: 3.5rem;
  margin-bottom: 0;
}

.input_field.is-faucet-repair {
  display: none;
}

.total-deduction {
  font-size: 4rem;
}

.total-deduction.is-red {
  color: var(--is-red);
}

.total-deduction.is-range {
  font-size: 3rem;
  line-height: 3.5rem;
}

.calc-result_total-wrapper {
  z-index: 999;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  display: flex;
  overflow: hidden;
}

.calc-result_text-style-h2 {
  color: var(--is-blue);
  justify-content: center;
  align-items: center;
  display: flex;
}

.calc_form-input-field-wrapper {
  grid-row-gap: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.calc_form-input-field-wrapper.is-hidden {
  padding-bottom: 12%;
  display: none;
}

.calc_form-input-field-wrapper.is-hidden.is-expenses {
  padding-bottom: 0%;
  display: flex;
}

.clear-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.button-slider-nav {
  background-color: var(--is-blue);
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1rem;
  display: flex;
}

.button-slider-nav:hover {
  background-color: var(--hover-blue);
}

.button-slider-nav.is-hidden {
  display: none;
}

.calc_slider-explain-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.calc_explain-text-style {
  color: var(--form-explain-question);
  cursor: pointer;
}

.calc_slider-explain-question {
  grid-column-gap: 0.5rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.calc_slider-explain-answer {
  text-align: center;
  width: 75%;
  display: none;
}

.prev-arrow {
  flex-direction: column;
  align-items: stretch;
  width: 125px;
  display: flex;
  inset: auto 50% 10% 0%;
}

.calc_slider-wrapper {
  min-width: 65%;
  max-width: 65%;
}

.calc_form-input-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.radio-button-label-2 {
  cursor: pointer;
}

.section_calc-proof {
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  display: flex;
}

.calc-proof_grid {
  grid-column-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80%;
}

.calc-proof_name {
  grid-column-gap: 0.5rem;
  align-items: flex-end;
  font-weight: 500;
  display: flex;
}

.socialproof-image2 {
  border-radius: 200px;
  height: 3rem;
}

.text-block_result-proof-content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.calc-proof_card {
  border: 1px solid var(--accessible-components-dark-grey);
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.calc-proof_content {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.text-block_result-proof-date {
  color: var(--accessible-components-dark-grey);
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
}

.div_result-proof-date-container {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

.slide-nav {
  background-color: #5a1c1c;
  justify-content: center;
  display: none;
}

.calc_form-no-input {
  justify-content: center;
  padding-bottom: 35%;
  display: flex;
}

.calc_form-no-input.business-expenses {
  padding-bottom: 5%;
  display: none;
}

.calc_text-style-h1 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 4rem;
}

.calc-result_edit-wrapper {
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.section_calc-result {
  justify-content: space-around;
  align-items: center;
}

.calc_form-submit-button {
  background-color: var(--is-blue);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 3rem;
  padding: 10px;
  font-size: 1rem;
  display: flex;
  position: absolute;
  bottom: 10%;
  right: 15%;
}

.calc_form-submit-button:hover {
  background-color: var(--hover-blue);
}

.calc_form-submit-button.is-calc-edit {
  text-decoration: none;
  position: static;
  top: 65%;
}

.calc-result_edit-header {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
}

.calc-result_edit-container {
  flex-direction: column;
  align-items: flex-start;
  display: none;
}

.calc-result_wrapper {
  grid-column-gap: 4px;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  display: flex;
}

.calc_form-quick-input-container {
  grid-column-gap: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.calc_form-quick-input-button {
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1.5px solid #1369e3;
  border-radius: 10px;
  width: 5rem;
  padding: 5px;
}

.calc_form-quick-input-button:hover {
  border-color: var(--hover-blue);
  background-color: var(--hover-blue);
  color: #fff;
}

.flex-center-horizontal {
  grid-column-gap: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-center-horizontal.large-margin {
  padding-top: 4rem;
  padding-bottom: 3rem;
}

.calc-result_cta-wrapper {
  width: 60%;
  display: flex;
}

.calc-result_cta-div {
  background-color: var(--is-light-blue);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  display: flex;
  box-shadow: 2px 2px 5px #0003;
}

.calc-result_cta-div.proof {
  max-width: 35rem;
}

.calc-result_proof-row {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

.calc-result_stars {
  max-height: 24px;
}

.calc-result_cta-header {
  color: #000;
  text-align: center;
  align-self: center;
  max-width: 40rem;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.5rem;
  font-weight: 400;
}

.calc-result_cta-header.is-cta {
  color: var(--is-blue);
  padding-top: 0;
  font-weight: 500;
}

.calc-result_cta-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.line-break {
  display: none;
}

.anchor {
  height: 125px;
}

.list-2 {
  list-style-type: disc;
}

.calc_form-select-style {
  background-color: var(--input-background);
  cursor: pointer;
  border-radius: 10px;
  width: 200px;
  height: 50px;
  padding-left: 15px;
  box-shadow: 1px 1px 3px 1px #0003;
}

.calc-breakdown_header {
  text-align: center;
  padding-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.calc-breakdown_header.font-linotype.bold {
  font-weight: 700;
}

.calc-proof_header {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.25rem;
  display: flex;
}

.calc-result_app-button-container {
  grid-column-gap: 1.5rem;
  display: flex;
}

.calc-result_cta-image {
  width: 300px;
}

.img_result-proof-stars {
  height: 32px;
}

.section_calc-breakdown {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
  display: flex;
}

.section_calc-breakdown.is-faucet-repair {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.calc-breakdown_container-faucet-repair {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--is-light-blue);
  border: 3px solid #000;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 5px;
}

.grid_tax-type {
  text-align: center;
}

.section_blog-header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.blog-header_bg-wrapper {
  background-image: linear-gradient(#0009, #0009);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
}

.blog-header_bg-wrapper.content-creator {
  background-image: linear-gradient(#0009, #0009), url('../images/ContentCreator_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.consultant {
  background-image: linear-gradient(#0009, #0009), url('../images/Consultant_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.house-sitter {
  background-image: linear-gradient(#0009, #0009), url('../images/Housesitter_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.landscaper {
  background-image: linear-gradient(#0009, #0009), url('../images/Landscaper_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.photographer {
  background-image: linear-gradient(#0009, #0009), url('../images/Photographer_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.plumber {
  background-image: linear-gradient(#0009, #0009), url('../images/Plumber_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.tutor {
  background-image: linear-gradient(#0009, #0009), url('../images/Tutor_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.uber-lyft-driver {
  background-image: linear-gradient(#0009, #0009), url('../images/UberLyftDriver_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.virtual-assistant {
  background-image: linear-gradient(#0009, #0009), url('../images/VirtualAssistant_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.writer {
  background-image: linear-gradient(#0009, #0009), url('../images/Writer_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.streamer {
  background-image: linear-gradient(#0009, #0009), url('../images/Streamer_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.babysitter {
  mix-blend-mode: multiply;
  background-image: linear-gradient(#0009, #0009), url('../images/Babysitter_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.small-business {
  background-image: linear-gradient(#0009, #0009), url('../images/SmallBusinessOwner_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.personal-trainer {
  background-image: linear-gradient(#0009, #0009), url('../images/PersonalTrainer_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.makeup-artist {
  background-image: linear-gradient(#0009, #0009), url('../images/MakeupArtist_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.expense-tracker {
  background-image: linear-gradient(#0009, #0009), url('../images/HoldingPhone_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
  margin-bottom: 1rem;
}

.blog-header_bg-wrapper.versus {
  background-image: linear-gradient(#0009, #0009), url('../images/QuestionMark_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
  margin-bottom: 1rem;
}

.blog-header_bg-wrapper.real-estate {
  background-image: linear-gradient(#0009, #0009), url('../images/RealEstateAgent_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog-header_bg-wrapper.creditcard {
  background-image: linear-gradient(#0009, #0009), url('../images/BlogFAQ_bg.webp');
  background-position:
    0 0,
    0 0;
  background-size: auto, auto;
}

.blog-header_bg-wrapper.cleaner {
  background-image: linear-gradient(#0009, #0009), url('../images/Cleaner_bg.webp');
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
}

.blog_intro-container {
  color: var(--white);
  text-align: center;
  padding: 4rem;
}

.blog_text-title {
  background-color: #0000;
}

.writeoff-header_p {
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blog_dropdown-toggle {
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 74px;
  overflow: auto;
}

.blog_dropdown {
  border: 3px solid var(--is-blue);
  background-color: var(--white);
  cursor: pointer;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 14rem;
  height: 3rem;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
  display: flex;
}

.blog_dropdown.is-finder {
  width: 14rem;
  height: 3rem;
  font-weight: 500;
}

.blog_writeoff-item {
  grid-column-gap: 5px;
  border: 3px solid var(--accessible-components-dark-grey);
  border-radius: 20px;
  align-items: center;
  min-height: 120px;
  margin-bottom: 15px;
  padding: 5px;
  display: flex;
}

.blog_deduction-icon {
  align-self: center;
  font-size: 50px;
  line-height: 50px;
}

.blog_deduction-header {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.container-medium {
  width: 100%;
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
}

.writeoff_item {
  border-bottom: 1px solid var(--dim-grey-shadow);
}

.blog_intro-job-div {
  justify-content: center;
  display: flex;
}

.section_blog-cta {
  flex-direction: column;
  display: flex;
}

.blog_dropdown-list {
  height: 30vh;
  margin-top: 45px;
  font-weight: 400;
  position: absolute;
  inset: 0%;
  overflow: auto;
}

.blog-header_wrapper {
  flex-direction: column;
  max-width: 75rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

.section_sitemap {
  flex-direction: column;
}

.sitemap_item.hide-map {
  flex-direction: column;
  display: none;
}

.blog_cta-wrapper {
  border: 3px solid var(--is-radio-input);
  box-shadow: 1px 1px 3px 0 var(--dim-grey-shadow);
  background-color: #f2f7fc00;
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  align-items: center;
  width: 75%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
  overflow: visible;
}

.text-block_cta-header {
  text-align: left;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.cta_image-wrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 30%;
  margin-right: 20px;
}

.cta_content-wrapper {
  grid-row-gap: 0.75rem;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  width: 60%;
  display: flex;
}

.blog_cta-button-div {
  grid-column-gap: 2.5rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  display: flex;
}

.cta-button-secondary {
  cursor: pointer;
}

.cta_logo {
  width: 150px;
  display: none;
}

.cta_image-stars {
  height: 32px;
}

.blog_cta-button {
  background-color: var(--is-blue);
  box-shadow: 1px 1px 3px 0 var(--dim-grey-shadow);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  font-weight: 500;
  line-height: 1.5rem;
}

.blog_cta-button:hover {
  background-color: var(--hover-blue);
}

.blog_cta-feature-container {
  grid-row-gap: 1rem;
  flex-direction: column;
  line-height: 1.5rem;
  display: flex;
}

.text-span-6 {
  color: var(--is-blue);
}

.blog_find-savings-wrapper {
  border: 3px solid var(--dim-grey-shadow);
  background-color: var(--is-light-blue);
  border-radius: 20px;
  flex-direction: row;
  align-self: center;
  align-items: flex-end;
  width: 75%;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
}

.blog_dropdown-emoji {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 3rem;
}

.text-size-large {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.text-size-medium {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.blog_find-content-container {
  grid-row-gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.blog_cta-find-deduction-container {
  border-left: 1px solid var(--dim-grey-shadow);
  text-align: center;
  flex-direction: column;
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  display: flex;
}

.text-color-blue {
  color: var(--is-blue);
}

.text-color-gray {
  color: var(--dim-grey-shadow);
}

.clear-bottom {
  margin-bottom: 0;
}

.blog_padding-vertical {
  padding-bottom: 0.5rem;
}

.blog_comparison-wrapper {
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.shadow {
  box-shadow: 1px 1px 3px 2px #0003;
}

.blog_company-container {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.image-size_logo {
  max-height: 75px;
}

.section_app-review {
  background-color: var(--is-light-blue);
  border-radius: 10px;
  margin-top: 2rem;
  padding: 1rem;
  box-shadow: 1px 1px 3px 3px #0003;
}

.spacer-small {
  height: 1.5rem;
}

.horizontal-flex-center {
  align-items: center;
  display: flex;
}

.button-horizontal-wrapper {
  grid-column-gap: 2rem;
  display: flex;
}

.blog_user-review-wrapper {
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
}

.image_user-revew-header {
  object-fit: cover;
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  margin-top: 16px;
}

.circle-portrait {
  border-radius: 200px;
  width: 64px;
}

.googleplay-review,
.googleplay-stars {
  grid-column-gap: 1rem;
  align-items: center;
  display: flex;
}

.review-stars-image {
  width: 100px;
}

.user-review-wrapper-2 {
  grid-row-gap: 1rem;
  border: 1px solid var(--dim-grey-shadow);
  background-color: var(--white);
  flex-direction: column;
  margin-top: 16px;
  padding: 8px;
  display: flex;
}

.user-review-row {
  grid-row-gap: 0.5rem;
  flex-direction: column;
  display: flex;
}

.swiper {
  width: 100%;
}

.swiper-slide {
  flex: none;
  width: 500px;
  margin-right: 20px;
}

.swiper-slide.cc-price-table {
  width: 100%;
  margin-right: 0;
}

.pricing-table_mobile-nav-icon {
  color: #faf7f2;
  width: 16px;
  line-height: 0.9;
}

.price-table {
  flex-direction: column;
  display: flex;
}

.price-table.vs {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.price-table.is-mobile {
  display: none;
}

.price-table_options {
  position: relative;
}

.price-table_cell-last-accent-bg {
  background-color: var(--is-light-blue);
  border-bottom: 1px solid #5950f1;
  border-left: 1px solid #5950f1;
  border-right: 1px solid #5950f1;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 48px;
  margin-left: -1px;
  margin-right: -1px;
  position: absolute;
  inset: auto 0% -49px;
}

.cta-button_compare {
  background-color: var(--is-blue);
  color: #fff;
  border-radius: 100px;
  padding: 12px 16px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.cta-button_compare:hover {
  background-color: var(--hover-blue);
}

.price-table_cell {
  text-align: center;
  border-bottom: 1px solid #49484a1a;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 14.285%;
  padding: 30px 10px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: flex;
}

.price-table_cell.cc-title {
  text-align: left;
  align-items: flex-start;
}

.price-table_cell.cc-title.cc-callout-horz {
  border-left-style: solid;
  border-left-color: var(--is-blue);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.price-table_cell.cc-header {
  border-bottom-style: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.price-table_cell.cc-header.vs {
  width: 33.33%;
}

.price-table_cell.cc-callout {
  border-right: 1px solid var(--is-blue);
  background-color: var(--is-light-blue);
  border-left: 1px solid #5950f1;
  position: relative;
}

.price-table_cell.cc-callout.cc-header {
  background-color: var(--is-light-blue);
  border-top: 1px solid #5950f1;
}

.price-table_cell.vs {
  width: 33.33%;
}

.price-table_cell.cc-callout {
  background-color: var(--is-light-blue);
  border-left: 1px solid #5950f1;
  border-right: 1px solid #5950f1;
  position: relative;
}

.price-table_cell.cc-callout-horz {
  border-style: solid none;
  border-width: 1px;
  border-color: var(--is-blue) black;
  background-color: var(--is-light-blue);
}

.price-table_cell.cc-callout-horz-end {
  border-style: solid solid solid none;
  border-width: 1px;
  border-color: var(--is-blue) var(--is-blue) var(--is-blue) black;
  background-color: var(--is-light-blue);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.u-text-semibold {
  font-weight: 600;
}

.pricing-table_mobile-nav-btn {
  color: #fff;
  background-color: #5950f1;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.u-sr-only {
  white-space: nowrap;
  border: 0 solid #0000;
  width: 1px;
  height: 1px;
  margin-top: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pricing-table_mobile-nav {
  z-index: 1;
  justify-content: space-between;
  width: 100%;
  display: none;
  position: absolute;
  inset: 0% auto auto 0%;
}

.price-table_row {
  display: flex;
}

.swiper-wrapper {
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.swiper-wrapper.cc-price-table {
  flex-direction: column;
  align-items: stretch;
}

.price-table_text {
  flex: 1;
  font-size: 1.25rem;
}

.clear-top {
  margin-top: 0;
}

.cta-button-blog {
  color: var(--white);
  text-align: center;
  background-color: #1369e3;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  max-width: 21rem;
  margin-top: 15px;
  padding: 12px 16px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  box-shadow: 1px 1px 3px #5e5e5e;
}

.cta-button-blog:hover {
  background-color: var(--hover-blue);
}

.cta-button-blog.is-short {
  box-shadow: 1px 1px 3px 0 var(--dim-grey-shadow);
  max-width: 10rem;
}

.contents_wrapper {
  background-color: var(--is-light-blue);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 25rem;
  margin-bottom: 0;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.contents_list-style {
  grid-row-gap: 0.75rem;
  flex-direction: column;
  padding-left: 0;
  text-decoration: none;
  list-style: none;
  display: flex;
}

.price-table-wrapper {
  margin-bottom: 2.5rem;
}

.blog-item_container {
  margin-bottom: 2rem;
}

.blog_text-style-h2 {
  margin-bottom: 0.5rem;
}

.free-tax_padding-horizontal {
  padding-left: 4rem;
  padding-right: 4rem;
}

.section_contents {
  flex-direction: column;
}

.free-calculator_card {
  grid-row-gap: 0.5rem;
  background-color: var(--white);
  color: var(--black);
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 10rem;
  height: 7rem;
  padding: 1rem 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  text-decoration: none;
  display: flex;
  box-shadow: 4px 4px 10px 1px #0003;
}

.free-calculator_card:hover {
  background-color: var(--card_blue);
}

.free-calculator_grid {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.free-calculator_icon {
  width: 3rem;
  height: 3rem;
}

.free-tax_deduction-wrapper {
  border-radius: 10px;
  padding-right: 1rem;
  display: flex;
  box-shadow: 4px 4px 10px 1px #0003;
}

.free-tax_article-image {
  border-radius: 10px;
  width: 200px;
  min-width: 200px;
}

.free-tax_article-content {
  flex-direction: column;
  width: 100%;
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
}

.free-tax_article-surtitle {
  color: var(--is-blue);
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
}

.free-tax_article-h3 {
  color: var(--black);
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.free-tax_article-p {
  color: var(--black);
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5rem;
}

.free-tax_article-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.free-tax_article-wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: space-around;
  display: flex;
}

.free-tax_article-container {
  grid-row-gap: 1rem;
  cursor: pointer;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
  padding: 1.25rem;
  text-decoration: none;
  display: flex;
  box-shadow: 4px 4px 10px 1px #0003;
}

.free-tax_article-container:hover {
  background-color: var(--is-light-blue);
}

.find-deduction_selector {
  align-items: center;
  display: flex;
}

.blog_article-faq {
  white-space: normal;
  margin-top: 0;
  margin-bottom: 0;
}

.free-tax_article-subtext {
  color: var(--is-blue);
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
}

.link-contentwrap:hover {
  color: #fff;
}

.droptext1 {
  color: var(--black);
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.droptext1.weight-400 {
  margin-bottom: 0;
  font-weight: 400;
}

.droptext1._2 {
  font-weight: 400;
}

.features-icon {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 36px;
  display: flex;
}

.platform-link {
  grid-column-gap: 5px;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
}

.platform-link:hover {
  background-color: var(--card_blue);
}

.section_faq-header {
  background-color: var(--ee-dark-blue);
  color: var(--white);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.clear-bullets {
  list-style: none;
}

.nav-dropdown-2 {
  position: static;
}

.nav-link-3 {
  color: var(--black);
  border-bottom: 1px solid #0000;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.125rem;
  line-height: 1.688rem;
  display: flex;
  position: static;
}

.nav-link-3:hover {
  border-bottom-color: var(--is-blue);
}

.nav-link-3.is-blue {
  color: var(--is-blue);
}

.nav-link-3.is-login {
  color: var(--is-blue);
  width: 7rem;
}

.nav-dropdown-accordion {
  width: 100%;
}

.nav-dropdown-accordion.w--open {
  background-color: #0000;
  padding-top: 40px;
  left: 0;
}

.show-mobile {
  display: none;
}

.nav-dropdown-div {
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  background-color: var(--white);
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: end;
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  display: grid;
  position: fixed;
  top: 85px;
  left: 0%;
  right: 0%;
  overflow: hidden;
  box-shadow: 0 7px 12px #47474733;
}

.nav-dropdown-div.faq {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  width: 20vw;
  padding-bottom: 0;
}

.nav-elements-2 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: relative;
}

.ham-button {
  position: static;
}

.nav-bar-2 {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #fffc;
  width: 100%;
  height: 4rem;
  position: sticky;
  top: 0;
}

.nav-menu-3 {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.ham-icon {
  font-size: 2rem;
}

.nav-login-container {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.nav-login-container.show-mobile,
.nav-login-container.show-mobile.is-industry {
  display: none;
}

.feature-hero_content-wrapper {
  grid-row-gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  display: flex;
}

.feature-hero_container {
  justify-content: flex-start;
  width: 100%;
  max-width: 71rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 2rem 5rem;
  display: flex;
}

.feature_demo-container {
  justify-content: center;
  width: 250px;
}

.feature_padding-horizontal {
  padding-left: 2rem;
  padding-right: 2rem;
}

.section_features {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.feature_wrapper {
  justify-content: space-around;
  display: flex;
}

.feature_wrapper.is-reversed {
  flex-direction: row-reverse;
}

.feature_text-style-h2 {
  max-width: 35rem;
  margin-top: 1.25rem;
  margin-bottom: 0.1rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}

.feature_text-style-p {
  max-width: 28rem;
  margin-bottom: 2.5rem;
  font-family:
    Open Sans,
    sans-serif;
}

.feature_bullet-text {
  font-size: 1.125rem;
}

.feature_bullet-container {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 1.5rem;
  padding-bottom: 0;
  line-height: 1.5rem;
  display: flex;
}

.feature_content-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.feature-hero_button-container {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-flow: row;
  align-items: center;
  margin-bottom: 1.25rem;
  display: flex;
}

.feature_demo-button {
  color: var(--is-blue);
  cursor: pointer;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  text-decoration: none;
  display: flex;
}

.feature_demo-button:hover {
  text-decoration: underline;
}

.image-28px {
  width: 28px;
  height: 28px;
}

.image-50px {
  width: 50px;
  height: 50px;
}

.feature_cta-button {
  background-color: var(--is-blue);
  box-shadow: 1px 1px 3px 0 var(--dim-grey-shadow);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  min-height: 50px;
  padding: 12px 16px;
  font-weight: 500;
  line-height: 1.5rem;
}

.feature_cta-button:hover {
  background-color: var(--hover-blue);
}

.section-spacing-vertical {
  grid-column-gap: 14rem;
  grid-row-gap: 14rem;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

.feature_subtext {
  color: #747474;
  font-weight: 300;
}

.feature-hero_stars {
  width: 15rem;
  margin-bottom: 0.25rem;
}

.feature-hero_label-style {
  color: var(--is-blue);
  background-color: #def;
  border-radius: 6px;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 5px 10px;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.feature-hero_label-div {
  display: flex;
}

.div_landing-learn {
  width: 100%;
  display: flex;
}

.video-wrapper {
  z-index: 1000;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #000000e3;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0%;
}

.video {
  width: 57.5%;
}

.video-div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.video-close {
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  inset: 20px 20px auto auto;
}

.feature_image-container-1 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.feature_image-container-1.bg-video {
  width: 400px;
  height: 400px;
}

.section_feature-proof {
  background-color: var(--is-light-blue);
  border-radius: 10px;
  margin-top: 2rem;
  padding: 1rem 4rem 4rem;
  box-shadow: 1px 1px 3px 3px #0003;
}

.feature-proof_review-wrapper {
  grid-column-gap: 2rem;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  max-width: 71rem;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.feature-proof_header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.feature-proof_review-container {
  grid-row-gap: 1rem;
  border: 1px solid var(--dim-grey-shadow);
  background-color: var(--white);
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  margin-top: 16px;
  padding: 8px;
  display: flex;
}

.feature-hero_text {
  padding-bottom: 0.5rem;
  font-family: Poppins, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.text-style-light {
  font-family:
    Open Sans,
    sans-serif;
  font-weight: 300;
}

.industry_grey-text-block {
  color: #6e6e6e;
  padding-bottom: 2rem;
}

.spaced-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.landing_banner-row-text-2 {
  font-size: 4.5rem;
  line-height: 5rem;
}

.industry_review-score-text {
  color: #000;
  font-size: 1.5rem;
}

.app-store-image {
  opacity: 0.87;
  height: 3rem;
}

.industry_review-container {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.industry_hero-wrapper {
  grid-column-gap: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.landing_padding-horizontal {
  padding-left: 4rem;
  padding-right: 4rem;
}

.landing_padding-horizontal.is-hero {
  padding-left: 0;
  padding-right: 0;
}

.landing_padding-horizontal.is-hero.card-landing {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.landing_padding-horizontal.is-hero.card-landing-dark {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #2f2f2f;
  background-image: radial-gradient(circle at 80% 60%, #353535 28%, #222);
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.landing_feature-content {
  grid-row-gap: 1rem;
  flex-direction: column;
  width: 50%;
  display: flex;
}

.proof_stars {
  width: 15rem;
}

.proof-header {
  width: 100%;
  max-width: 71rem;
  margin-left: auto;
  margin-right: auto;
  padding: 4.5rem 4rem;
}

.faq_padding-horizontal {
  padding-left: 4rem;
  padding-right: 4rem;
}

.faq_padding-vertical {
  padding-bottom: 2rem;
}

.free-calculator_wrapper {
  justify-content: center;
  display: flex;
}

.find-deduction_dropdown {
  border: 3px solid var(--is-blue);
  background-color: var(--white);
  cursor: pointer;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 14rem;
  height: 3rem;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
  display: flex;
}

.find-deduction_dropdown-toggle {
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 74px;
  overflow: auto;
}

.find-deduction_dropdown-list {
  height: 30vh;
  margin-top: 45px;
  font-weight: 400;
  position: absolute;
  inset: 0%;
  overflow: auto;
}

.feature-proof_review-header {
  grid-row-gap: 0.5rem;
  flex-direction: column;
  display: flex;
}

.feature-proof_review-row-1,
.feature-proof_review-row-2 {
  grid-column-gap: 1rem;
  align-items: center;
  display: flex;
}

.calc-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 71rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-left: 0.5rem;
  padding-right: 3.5rem;
}

.calc_text-style-total {
  color: var(--is-blue);
  justify-content: center;
  align-items: flex-end;
  font-size: 4rem;
  line-height: 6rem;
}

.calc_text-style-total.is-faucet-repair {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0;
  line-height: 0;
}

.calc-result_container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 71rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.industry_padding-horizontal {
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
}

.industry_hero-container {
  grid-column-gap: 2rem;
  justify-content: center;
  width: 100%;
  max-width: 71rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5rem;
  padding-bottom: 7.5rem;
  display: flex;
}

.card_reviews {
  grid-column-gap: 2rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card_reviews.card {
  margin-top: -50px;
  margin-bottom: 0;
}

.card_reviews.card.is-landing {
  justify-content: flex-start;
  margin-top: 1rem;
}

.card_reviews.card.is-landing.dark {
  opacity: 0.75;
}

.industry_header-1 {
  color: #8d8d8d;
  text-align: center;
  margin-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  font-weight: 300;
}

.industry_text-style-p {
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 300;
}

.industry_text-style-p.is-subtext {
  color: #747474;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
  display: block;
}

.industry_text-style-p.is-subtext.review-text {
  margin-top: -0.9rem;
}

.industry_text-style-p.is-hero {
  max-width: none;
  margin-bottom: 1rem;
  font-weight: 300;
}

.industry_text-style-p.is-hero.flex-center {
  text-align: center;
}

.industry_text-style-p.is-hero.flex-center.industry {
  margin-bottom: 2.8rem;
}

.industry_button {
  color: var(--white);
  text-align: center;
  background-color: #1369e3;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 250px;
  padding: 12px 32px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  box-shadow: 1px 1px 3px #5e5e5e;
}

.industry_button:hover {
  background-color: var(--hover-blue);
}

.industry_button.is-mobile {
  display: none;
}

.industry_button.pricing-button {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.industry_button.card-landing {
  margin-bottom: 5px;
}

.industry_review-stars {
  opacity: 0.74;
  width: 8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.industry_review-stars.thank-you {
  width: 15rem;
}

.industry_review-score {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.industry_review-subtext {
  color: #747474;
  font-weight: 300;
}

.proof_header-div {
  width: 100%;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 4rem 2rem;
}

.industry_banner-subtext {
  text-align: left;
  margin-top: 1rem;
}

.industry_banner-header-wrapper {
  text-align: center;
  width: 60%;
}

.industry_banner-header-wrapper.landing {
  text-align: left;
  flex-direction: column;
  width: 75%;
  display: flex;
}

.industry_text-style-h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.industry_text-style-h2.is-socialproof {
  max-width: 40rem;
  margin-top: 0;
  margin-bottom: 0;
}

.industry_text-style-h2.industry-specific {
  max-width: 100%;
  margin-bottom: 0.3rem;
}

.industry_text-style-h2.industry-specific.flex-center {
  text-align: center;
}

.industry_text-style-h2.time-banner,
.industry_text-style-h2.banner-1 {
  text-align: left;
}

.industry_banner-row-wrapper {
  grid-column-gap: 2rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.industry_banner-row-wrapper.landing {
  width: 75%;
}

.section_feature-banner {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.section_feature-banner.is-light-grey {
  background-color: var(--is-light-grey);
}

.industry_list-wrapper {
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.industry_list-item {
  grid-column-gap: 0.75rem;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  display: flex;
}

.industry_list-item-text {
  font-size: 1.125rem;
}

.industry_banner-container {
  grid-row-gap: 4rem;
  flex-direction: column;
  width: 100%;
  max-width: 71rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 7.5rem;
  display: flex;
}

.industry_banner-container.landing {
  padding: 5rem 0;
}

.landing_banner-row-container {
  grid-column-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.landing_banner-row-text-3 {
  font-size: 1rem;
}

.industry_padding-vertical {
  padding-bottom: 3rem;
}

.industry_padding-vertical.is-showcase {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.industry_padding-vertical.top-padding {
  padding-top: 3rem;
}

.industry_padding-vertical.is-pricing {
  padding-top: 5rem;
  padding-bottom: 7rem;
}

.industry_padding-vertical.proof {
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.industry_padding-vertical.proof.landing {
  padding-bottom: 5rem;
}

.industry_pricing-content {
  flex-direction: column;
  width: 45%;
  display: flex;
}

.industry_pricing-wrapper {
  justify-content: space-between;
  display: flex;
}

.industry_pricing-select {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  display: flex;
}

.container-largest {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 85rem;
  margin-left: auto;
  margin-right: auto;
}

.container-largest.flex-center {
  flex-direction: row;
  display: flex;
}

.landing_text-style-h1 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 3.9rem;
  font-weight: 700;
  line-height: 4.2rem;
}

.landing_text-style-h1.flex-center {
  text-align: center;
}

.landing_text-style-h1.flex-center.industry {
  margin-bottom: 1.5rem;
}

.feature-content-wrapper {
  background-color: #f5f5f7;
  border-radius: 1.875rem;
  width: 100%;
  height: 25rem;
  overflow: hidden;
}

.feature-list-wrapper {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  max-width: 67.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.font-smoothing {
  display: none;
}

.main-wrapper-2 {
  background-color: #e5eef9;
  width: 100vw;
  height: 100vh;
}

.feature-heading-2 {
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 1.2;
}

.feature-list-track {
  width: 22rem;
  margin-right: 7rem;
  padding-top: 50vh;
  padding-bottom: 50vh;
}

.full-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: none;
}

.full-image._1 {
  object-fit: cover;
}

.feature-text {
  opacity: 0.2;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  transition: opacity 0.2s;
}

.feature-content-track {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25rem;
  height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
}

.text-block-45 {
  font-size: 3rem;
}

.industry_show-case-wrapper {
  display: flex;
}

.showcase_item-wrapper {
  grid-column-gap: 0.5rem;
  white-space: normal;
  align-items: center;
  display: flex;
}

.showcase_icon {
  width: 48px;
  height: 48px;
}

.showcase_item-subtext {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5rem;
}

.showcase_item-header {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 2rem;
}

.link-block {
  color: #000;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  transition:
    background-color 1s cubic-bezier(0.19, 1, 0.22, 1),
    box-shadow 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.link-block:hover {
  background-color: var(--white);
  box-shadow: 5px 5px 12px #0003;
}

.div-block-15 {
  grid-row-gap: 0.25rem;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  display: flex;
}

.section_showcase {
  background-color: var(--card_blue);
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.section_showcase.card-landing {
  display: flex;
}

.showcase_header-text {
  padding-left: 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
}

.showcase_header-text.new-font {
  font-family: Poppins, sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
}

.image-div {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.showcase-image {
  opacity: 0.63;
  object-fit: cover;
  position: absolute;
  inset: 0%;
}

.image-showcase-wrapper {
  justify-content: center;
  width: 50%;
  height: 500px;
  display: flex;
}

.section_pricing {
  background-color: var(--card_blue);
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.section_pricing.is-light-blue {
  background-color: var(--is-light-blue);
}

.div-block-16 {
  grid-row-gap: 1rem;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
}

.div-block-17 {
  grid-column-gap: 0.5rem;
  display: flex;
}

.div-block-18 {
  align-items: center;
  display: flex;
}

.text-block-47 {
  font-size: 3rem;
}

.text-block-48 {
  background-color: #cfcfcf;
  border-radius: 20px;
  margin-left: 10px;
  padding: 5px;
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-block-49 {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.default-padding-horizontal {
  padding-left: 4rem;
  padding-right: 4rem;
}

.text-style-h2 {
  font-size: 2.5rem;
  line-height: 3rem;
}

.accordion-item-3rem {
  width: 100%;
  overflow: hidden;
}

.accordion-item-3rem.is-landing {
  display: none;
}

.list-3 {
  list-style: decimal;
}

.list-item {
  list-style: lower-alpha;
}

.get-started_list {
  padding-left: 0;
  list-style: square;
}

.get-started_header {
  background-color: var(--card_blue);
  margin-bottom: 0.75rem;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  font-size: 2rem;
  line-height: 3rem;
  box-shadow: 5px 5px 12px #0003;
}

.get-started_header.get-started {
  background-color: var(--card_blue);
  margin-bottom: 0;
  padding-left: 5px;
}

.faq_intro-p {
  margin-top: 1.25rem;
}

.blog_text-style-h3 {
  margin-bottom: 0;
}

.tab {
  background-color: var(--transparent);
  text-align: left;
  border-radius: 0.25rem;
  height: 100px;
  margin-left: 0;
  margin-right: 0;
  padding: 20px;
  font-size: 14px;
  line-height: 1.4;
  transition:
    box-shadow 0.5s linear,
    background-color 0.5s linear;
  overflow: hidden;
}

.tab.w--current {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 5px 5px 12px #0003;
}

.tab-fill {
  z-index: 0;
  background-color: #4a00e066;
  width: 0%;
  position: absolute;
  inset: 0%;
}

.tab-container {
  z-index: 2;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.tab-heading {
  font-weight: 700;
}

.tab-grid {
  grid-column-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.tab-image {
  border-radius: 20px;
  max-height: 500px;
  box-shadow: 0 0 12px #0000004d;
}

.tab-image-wrapper {
  justify-content: center;
  display: flex;
}

.tab-image-wrapper.center {
  height: 100%;
  max-height: 100vh;
}

.tab-menu {
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: none;
  margin-top: 0;
  margin-bottom: 35px;
  display: flex;
}

.tab-subtext {
  z-index: 1;
  color: #ffffff80;
  position: relative;
}

.paragraph-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.paragraph-wrapper.inner-padding-small {
  color: #fff;
}

.tab-text {
  z-index: 1;
  font-size: 1rem;
  position: relative;
}

.auto-rotate-script,
.auto-rotate-styles {
  display: none;
}

.toggle---brix {
  background-color: var(--is-blue);
  cursor: pointer;
  border-radius: 100px;
  width: 74px;
  min-width: 74px;
  height: 33px;
  min-height: 33px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
}

.toggle-bullet---brix {
  background-color: #fff;
  border-radius: 100px;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  inset: 0% 6px 0% auto;
}

.div-block-19 {
  display: flex;
}

.div-block-20 {
  align-items: center;
  display: flex;
}

.card_h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 3.6rem;
}

.card_h1.is-blue {
  color: var(--is-blue);
}

.card_h1.left-align {
  text-align: left;
}

.card_h1.left-align.cardpullover {
  width: 400px;
  font-size: 3.2rem;
}

.card_h1.is-landing {
  text-align: left;
}

.marquee_card-2 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: #f7f8fa;
  border-radius: 9px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 30rem;
  height: 100%;
  padding: 1rem;
  display: flex;
}

.tabs-wrapper {
  margin-top: -3.5rem;
  overflow: visible;
}

.tabs-wrapper.is-industry {
  margin-top: -1.5rem;
}

.link-block-2 {
  text-decoration: none;
}

.div-block-21 {
  display: flex;
}

.html-embed {
  width: 275px;
}

.feature_item-wrapper-m {
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  display: flex;
}

.showcase_padding-horizontal {
  padding-left: 4rem;
  padding-right: 4rem;
}

.showcase_padding-vertical {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.section_showcase-mobile {
  background-color: var(--card_blue);
  flex-direction: column;
  display: none;
}

.showcase_item {
  border-bottom: 1px solid var(--dim-grey-shadow);
}

.showcase_item.is-last {
  border: 1px #000;
}

.section_getting-started {
  flex-direction: column;
}

.landing_hero-content-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  width: 85%;
  display: flex;
}

.landing_hero-content-wrapper.sticky {
  z-index: -5;
  justify-content: center;
  align-items: flex-start;
  height: 250vh;
  display: flex;
  position: absolute;
}

.landing_hero-content-wrapper.card-landing {
  justify-content: center;
}

.landing_hero-container-2a {
  grid-row-gap: 5rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5rem;
  padding-bottom: 7.5rem;
  display: flex;
}

.landing_hero-container-2a.landing {
  padding: 5rem 0;
}

.div-block-22 {
  flex-direction: column;
}

._450px {
  max-height: 450px;
}

.landing_hero-image-container {
  padding-top: 1rem;
}

.card_hero_center {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 900px;
  display: flex;
}

.card_hero_center.sticky {
  position: static;
  top: -17.8vh;
}

.card_hero_center.blue {
  background-color: #d7e5f6;
  border-radius: 20px;
  width: 85%;
  max-width: 1200px;
  padding: 64px 32px;
  box-shadow: 4px 5px 20px 4px #0e5ab63d;
}

.landing_hero-banner-wrapper {
  grid-column-gap: 2rem;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  display: flex;
}

.landing_hero-banner-wrapper.landing {
  width: 75%;
}

.industry_padding-vertical-copy {
  padding-bottom: 3rem;
}

.industry_padding-vertical-copy.is-showcase {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.industry_padding-vertical-copy.top-padding {
  padding-top: 3rem;
}

.industry_padding-vertical-copy.is-pricing {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.industry_padding-vertical-copy.proof {
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.industry_padding-vertical-copy.proof.landing {
  padding-bottom: 5rem;
}

.proof_padding-vertical {
  background-color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.heading-31 {
  text-align: center;
  font-family:
    Open Sans,
    sans-serif;
}

.section-2 {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.card_text_secondary {
  text-align: center;
  margin-top: 10px;
  font-family:
    Open Sans,
    sans-serif;
  font-size: 1rem;
}

.container {
  margin-top: 2px;
}

.card_text {
  text-align: center;
  font-family:
    Open Sans,
    sans-serif;
}

.card_text.steps {
  margin-left: 32px;
  margin-right: 32px;
}

.card_text.secondary {
  color: #7c7c7c;
  margin-top: 10px;
}

.div-block-23 {
  border-radius: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  margin-top: -150px;
  padding-bottom: 100px;
  display: flex;
  overflow: hidden;
}

.image-31 {
  margin-top: -74px;
  margin-bottom: 30px;
}

.card_step_container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 14px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 11px;
  display: flex;
}

.image-32 {
  height: 200px;
  position: relative;
  top: 83.375px;
  left: -45.93px;
}

.image-33 {
  margin-bottom: 26px;
  margin-left: 104px;
  position: static;
}

.div-block-24 {
  display: flex;
  position: sticky;
  top: 25vh;
}

.image-34 {
  max-width: 45%;
  height: auto;
  position: static;
}

.image-35 {
  width: 35%;
  height: auto;
}

.div-block-25 {
  background-image: linear-gradient(#fff0, #fff 73%);
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  display: flex;
}

.card_main_body {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
}

.image-36 {
  width: 35%;
  margin-left: 28px;
}

.image-37 {
  width: 40%;
  padding-right: 32px;
  display: block;
}

.card_full_screen {
  height: 90vh;
  min-height: 600px;
}

.card_steps {
  justify-content: center;
  align-items: flex-start;
  max-width: 100vw;
  display: flex;
}

.card_step_emoji {
  width: 48px;
}

.card_feature {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  max-width: 1000px;
  margin-bottom: 192px;
  display: flex;
}

.card_h2 {
  font-family:
    Palatino Linotype,
    Book Antiqua,
    Palatino,
    serif;
  font-size: 2rem;
  font-weight: 400;
}

.image-39 {
  object-fit: contain;
  max-width: 35%;
  height: auto;
}

.card_featuretext {
  font-family:
    Open Sans,
    sans-serif;
}

.card_featuretext.center {
  text-align: center;
}

.div-block-26 {
  margin-right: 60px;
}

.image-40 {
  object-fit: contain;
  max-width: 35%;
  margin-right: -30px;
}

.image-41 {
  width: 32px;
}

.card_smallfeatureimage {
  display: none;
}

.div-block-27 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  min-height: 600px;
  margin-top: -113px;
  padding-top: 0;
  display: flex;
}

.is-blue {
  color: var(--is-blue);
}

.div-block-28 {
  position: absolute;
  inset: 10% 0% auto;
}

.new-font {
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.calc-breakdown_container-income-tax {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #f2f7fc;
  border: 3px solid #000;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 5px;
}

.calc-breakdown_grid-income-tax {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template:
    '.'
    '.'
    '.'
    '.'
    '.'
    '.'
    / 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  overflow: auto;
}

.body-2 {
  background-color: #fefefe;
  overflow: visible;
}

.div-block-29 {
  width: 400px;
}

.image-42 {
  object-fit: contain;
  width: 500px;
  height: 600px;
}

.image-43,
.image-44 {
  position: absolute;
}

.image-45 {
  object-fit: contain;
  border-radius: 30px;
  height: 100%;
  box-shadow: 0 20px 100px 8px #0003;
}

.image-46 {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: absolute;
}

.image-47 {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: absolute;
  inset: 0%;
}

.cardmainexplainer {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  max-width: 1000px;
  min-height: 60vh;
  margin-bottom: 0;
  display: flex;
}

.div-block-30 {
  width: 300px;
  height: 60vh;
  margin-left: 30px;
  margin-right: 12px;
  padding-right: 0;
  display: flex;
  position: relative;
}

.image-48 {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: absolute;
}

.image-49 {
  object-fit: contain;
  border-radius: 24px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  box-shadow: 0 0 100px 20px #0003;
}

.cardexplainertext {
  text-align: left;
  width: 440px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 4rem;
}

.cardexplainertext.is-blue {
  color: var(--is-blue);
}

.cardexplainertext.left-align {
  text-align: left;
}

.cardexplainertext.left-align.cardpullover {
  width: 400px;
  font-size: 3.2rem;
}

.cardexplainertext.is-landing {
  text-align: left;
}

.cardexplainertext.bottom {
  display: none;
}

.image-50 {
  height: 50%;
  margin-top: 41px;
  margin-left: -107px;
  position: absolute;
}

.image-51 {
  object-fit: contain;
  height: 80%;
  position: absolute;
  top: 8px;
  left: 19px;
}

.image-52 {
  background-color: #f4f5f7;
  border-radius: 28px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  box-shadow: 0 3px 100px 20px #0003;
}

.image-53 {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
}

.image-54 {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
}

.image-55 {
  object-fit: contain;
  border-radius: 24px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  box-shadow: 0 2px 100px 20px #0003;
}

.image-56 {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: absolute;
}

.arrow {
  width: 50%;
  margin-top: 32px;
  margin-bottom: 32px;
  position: static;
}

.cardexplainersubtext {
  color: var(--brand);
  padding-bottom: 4px;
  font-family:
    Open Sans,
    sans-serif;
  font-weight: 600;
}

.cardexplainersubtext.center {
  text-align: center;
}

.cardexplainersubtext.top {
  display: block;
}

.cardexplainersubtext.bottom {
  display: none;
}

.image-57 {
  height: 40%;
  position: absolute;
  inset: auto 9% 9% auto;
}

.image-58 {
  object-fit: contain;
  width: 200px;
  margin-top: 53px;
}

.image-59 {
  object-fit: contain;
  height: 40%;
  margin-top: 32px;
  margin-left: 9px;
  position: absolute;
}

.image-60,
.image-61 {
  object-fit: contain;
  height: 70%;
  margin-top: 32px;
  padding-left: 12px;
  position: absolute;
}

.link {
  color: #1469e3;
}

.bold-text-4 {
  font-weight: 600;
}

.blog-text-style-h4 {
  margin-bottom: 0;
  font-size: 1.25rem;
}

.card_hero_left {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 900px;
  display: flex;
}

.card_hero_left.sticky {
  position: static;
  top: -17.8vh;
}

.card_hero_left.blue {
  background-color: #d7e5f6;
  border-radius: 20px;
  width: 85%;
  max-width: 1200px;
  padding: 64px 32px;
  box-shadow: 4px 5px 20px 4px #0e5ab63d;
}

.card_extra_bold {
  color: #fff;
  text-align: left;
  margin-top: -0.65rem;
  margin-bottom: 0.4rem;
  font-family: Poppins, sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4.75rem;
}

.card_extra_bold.is-blue {
  color: var(--is-blue);
}

.card_extra_bold.left-align {
  text-align: left;
}

.card_extra_bold.left-align.cardpullover {
  width: 400px;
  font-size: 3.2rem;
}

.card_extra_bold.is-landing {
  text-align: left;
}

.card_sub_header {
  color: #fff;
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.card_sub_header.steps {
  margin-left: 32px;
  margin-right: 32px;
}

.card_sub_header.secondary {
  color: #7c7c7c;
  margin-top: 10px;
}

.card_hero_left_update {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 700px;
  display: flex;
}

.card_hero_left_update.sticky {
  position: static;
  top: -17.8vh;
}

.card_hero_left_update.blue {
  background-color: #d7e5f6;
  border-radius: 20px;
  width: 85%;
  max-width: 1200px;
  padding: 64px 32px;
  box-shadow: 4px 5px 20px 4px #0e5ab63d;
}

.bold-text-5,
.bold-text-6 {
  color: #fff;
}

.review_text_offwhite {
  color: #d4d4d4;
}

.review_text_offwhite.dark {
  color: #000;
}

.reviews_text {
  color: #d3d3d3;
  font-weight: 300;
}

.reviews_text.font-black {
  color: #000;
}

.reviews_text.font-darkgrey {
  color: #747474;
}

.hero_gif_container {
  flex: 1;
  justify-content: center;
  height: 100%;
  display: flex;
}

.card_superbold {
  text-align: center;
  margin-bottom: -1.55rem;
  font-family: Poppins, sans-serif;
  font-size: 4.5rem;
  font-weight: 900;
  line-height: 6rem;
}

.phonescannergif {
  max-height: 550px;
}

.semitransparentwhite {
  color: #ffffffb0;
  text-align: left;
  margin-top: -13px;
  padding-bottom: 24px;
  font-family:
    Open Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1rem;
}

.semitransparentwhite.steps {
  margin-left: 32px;
  margin-right: 32px;
}

.semitransparentwhite.secondary {
  color: #7c7c7c;
  margin-top: 10px;
}

.cta_container {
  background-image: linear-gradient(#fff0, #fff 73%);
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
}

.featureimage {
  flex: none;
  max-height: 80vh;
}

.featureimage.absolute {
  position: absolute;
}

.featureimage.frame-shadow {
  background-image: url('../../marketing-scripts/webflow/background-image.svg');
  background-position: 0 0;
  background-size: auto;
  border-radius: 20px;
  max-height: 500px;
  box-shadow: 0 0 25px 10px #0003;
}

.text-block-50 {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 6rem;
}

.text-block-51 {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
}

.div-block-32 {
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 6rem;
  display: flex;
}

.div-block-33 {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-direction: column;
  width: 40%;
  display: flex;
}

.div-block-34 {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  font-family:
    Open Sans,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  display: flex;
}

.div-block-35 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.app-button_row {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  justify-content: flex-start;
  display: flex;
}

.div-block-36 {
  width: 40%;
}

.div-block-37 {
  width: 50%;
}

.div-block-38 {
  justify-content: center;
  padding-bottom: 32px;
  display: flex;
}

.landing_padding-vertical-copy {
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.landing_padding-vertical-copy.is-hero {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.landing_padding-vertical-copy.is-socialproof-over {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.thank-you_hero {
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 12rem;
  display: flex;
}

.image-62 {
  transform-style: preserve-3d;
  width: 100px;
  padding-top: 23px;
  padding-left: 12px;
}

.div-block-39 {
  justify-content: flex-start;
  display: flex;
}

.text-block-52 {
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  display: flex;
}

.text-block-53 {
  font-family: Poppins, sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.5rem;
}

.div-block-40 {
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.feature_demo-wrapper {
  justify-content: center;
  width: 50%;
  display: flex;
}

.feature-hero_ratings-wrapper {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.background-video {
  border-radius: 20px;
  width: 240px;
  height: 500px;
}

.bg-video_feature {
  border-radius: 20px;
  width: 290px;
  height: 600px;
  box-shadow: 0 0 25px 10px #0003;
}

.div-block-42 {
  background-color: #b17070;
  border-radius: 40px;
  width: 200px;
  height: 75px;
}

.div-block-43 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.wrapper_landing-hero {
  background-color: #2f2f2f;
  background-image: radial-gradient(circle at 80% 60%, #353535 28%, #222);
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 81vh;
  min-height: 700px;
  padding-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

.wrapper-content_landing-hero {
  flex-flow: column;
  width: 55%;
  display: flex;
}

.container-img_landing-hero {
  justify-content: center;
  align-items: stretch;
  width: 30%;
  display: flex;
  position: relative;
}

.section_landing-hero {
  background-color: #2f2f2f;
  background-image: radial-gradient(circle at 80% 60%, #353535 28%, #222);
  flex-flow: row;
}

.section_landing-hero-container {
  display: flex;
}

.container_landing-hero {
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 74rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.container_landing-hero.flex-horizontal-align-center {
  flex-direction: row;
  display: flex;
}

.container_landing-hero.vertical-align-center {
  flex-direction: column;
  display: flex;
}

.section_landing-features {
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.wrapper_landing-feature {
  flex-flow: column;
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4rem;
  padding-right: 2rem;
  display: flex;
}

.wrapper_landing-teams {
  justify-content: center;
  width: 100%;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 7rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

.wrapper_landing-teams.for-teams {
  background-color: #ecf3fd;
  border-radius: 20px;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.container_landing-teams-txt {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  width: 50%;
  display: flex;
}

.container_landing-teams-img {
  width: 50%;
  max-width: 450px;
}

.list-item_landing-teams {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  display: flex;
}

.feature-icon {
  width: 75px;
}

.icon_landing-teams {
  height: 55px;
  max-height: 55px;
}

.list-wrapper_landing-teams {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-flow: column;
  font-size: 1.5rem;
  line-height: 2.25rem;
  display: flex;
}

.image-64 {
  height: 60vh;
}

.image_landing-feature-item.wide {
  margin-top: 24px;
}

.container_landing-feature-img {
  justify-content: center;
  align-items: center;
  width: 35%;
  margin-right: 3%;
  display: flex;
}

.container_landing-feature-header {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  align-items: center;
  padding-top: 7rem;
  padding-bottom: 4rem;
  display: flex;
}

.item_landing-feature {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
}

.list_landing-feature {
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  flex-flow: column;
  width: 90%;
  padding-left: 30px;
  display: flex;
}

.img-arrow_landing {
  align-self: center;
  width: 40%;
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-right: 5%;
}

.container_landing-teams {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.padding_landing {
  padding-left: 4rem;
  padding-right: 4rem;
}

.font_landing-feature-h1 {
  text-align: left;
  margin-bottom: 2rem;
  font-family: Poppins, sans-serif;
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 4rem;
}

.font_landing-feature-h1.is-blue {
  color: var(--is-blue);
}

.font_landing-feature-h1.left-align {
  text-align: left;
}

.font_landing-feature-h1.left-align.cardpullover {
  width: 400px;
  font-size: 3.2rem;
}

.font_landing-feature-h1.is-landing {
  text-align: left;
}

.font_landing-feature-h1.bottom {
  display: none;
}

.font_landing-feature-h1.center {
  text-align: center;
}

.font_landing-feature-h1.for-teams,
.font_landing-feature-h1.clear-bottom,
.font_landing-feature-h1.clear-margin-bottom {
  margin-bottom: 0;
}

.font_landing-feature-subh1 {
  color: var(--brand);
  margin-bottom: 0.5rem;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.font_landing-feature-subh1.center {
  text-align: center;
}

.font_landing-feature-subh1.top {
  display: block;
}

.font_landing-feature-subh1.bottom {
  display: none;
}

.container_landing-feature-txt {
  width: 60%;
}

.center-txt {
  text-align: center;
}

.font_landing-teams-subtext {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
}

.nav-button_primary {
  z-index: 500;
  border: 1px solid var(--is-blue);
  color: var(--is-blue);
  text-align: center;
  background-color: #ebf2fd;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 3.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-decoration: none;
  display: flex;
}

.nav-button_primary.secondary {
  z-index: 1;
  left: 0;
}

.nav-button_primary.deselect {
  color: var(--black);
  background-color: #0000;
  border-style: none;
  left: 1px;
}

.nav-button_primary.deselect.is-pricing.w--current {
  box-shadow: none;
}

.nav-button_primary.is-plan.test.w--current {
  position: relative;
  right: -35%;
}

.nav-button_primary.is-pricing {
  background-color: #0000;
  border-style: none;
}

.nav-type-container {
  padding-left: 2rem;
  display: flex;
}

.nav-type-container.is-plan {
  padding-left: 0;
}

.nav-type-container.is-plan.test {
  margin-left: 0;
  padding-left: 0;
}

.nav-button_secondary {
  z-index: 1;
  color: var(--black);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 3.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-decoration: none;
  display: flex;
}

.nav-button_secondary.secondary {
  z-index: 1;
  left: 0;
}

.nav-button_secondary.selected {
  z-index: 500;
  color: var(--is-blue);
  background-color: #e9f0fb;
  border: 1px solid #1368e3;
}

.nav-button_secondary.selected.w--current {
  width: 10rem;
  left: -33.5%;
}

.nav-button_secondary.selected.is-pricing {
  border-style: none;
}

.nav-button_secondary.is-plan.test {
  left: 0%;
}

.nav-button_secondary.is-pricing {
  box-shadow: none;
  background-color: #fff0;
}

.nav-bar-3 {
  z-index: 999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #fffc;
  width: 100%;
  height: 5.5rem;
  position: sticky;
  top: 0;
}

.nav-elements-3 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: relative;
}

.nav-link-4 {
  color: var(--black);
  border-bottom: 1px solid #0000;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.125rem;
  line-height: 1.688rem;
  display: none;
  position: static;
}

.nav-link-4:hover {
  border-bottom-color: var(--is-blue);
}

.nav-link-4.is-blue {
  color: var(--is-blue);
}

.nav-button-v2 {
  color: #1369e3;
  text-align: center;
  background-color: #fff;
  border: 4px #1369e3;
  border-radius: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 8.5rem;
  height: 3.25rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  box-shadow: 1px 1px 3px #5e5e5e;
}

.nav-button-v2:hover {
  color: #fff;
  background-color: #283f5f;
  border-color: #283f5f;
}

.nav-button-v2.is-blue-fill {
  background-color: var(--is-blue);
  box-shadow: 1px 1px 3px 0 var(--dim-grey-shadow);
  color: #fff;
  width: auto;
  min-width: 8.5rem;
  padding-left: 35px;
  padding-right: 35px;
}

.nav-button-v2.is-blue-fill:hover {
  background-color: #283f5f;
}

.nav-button-v2.is-blue-fill.is-pricing,
.nav-button-v2.is-blue-fill.is-pricing.w--current {
  display: none;
}

.nav-button-v2-mobile {
  color: #1369e3;
  text-align: center;
  background-color: #fff;
  border: 4px #1369e3;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 7.5rem;
  height: 3.25rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  box-shadow: 1px 1px 3px #5e5e5e;
}

.nav-button-v2-mobile:hover {
  color: #fff;
  background-color: #283f5f;
  border-color: #283f5f;
}

.nav-button-v2-mobile.is-blue-fill {
  background-color: var(--is-blue);
  box-shadow: 1px 1px 3px 0 var(--dim-grey-shadow);
  color: #fff;
  border-radius: 30px;
}

.nav-button-v2-mobile.is-blue-fill:hover {
  background-color: #283f5f;
}

.nav-mobile-container {
  display: none;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-center-vertical {
  grid-column-gap: 32px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-center-vertical.large-margin {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.flex-center-vertical.largest-margin {
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.paragraph {
  font-size: 1.25rem;
}

.nav-icon_dropdown {
  margin-right: 0;
  padding-left: 4px;
  position: static;
}

.nav-link-4-v2 {
  color: var(--black);
  border-bottom: 1px solid #0000;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.125rem;
  line-height: 1.688rem;
  display: flex;
  position: static;
}

.nav-link-4-v2:hover {
  border-bottom-color: var(--is-blue);
}

.nav-link-4-v2.is-blue {
  color: var(--is-blue);
}

.nav-link-4-v2.is-login {
  color: var(--is-blue);
  width: 7rem;
}

.nav-link-3-copy {
  color: var(--black);
  border-bottom: 1px solid #0000;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.125rem;
  line-height: 1.688rem;
  display: flex;
  position: static;
}

.nav-link-3-copy:hover {
  border-bottom-color: var(--is-blue);
}

.nav-link-3-copy.is-blue {
  color: var(--is-blue);
}

.nav-link-3-copy.is-login {
  color: var(--is-blue);
  width: 7rem;
}

.padding-horizontal_landing {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.img_app-store-logo {
  opacity: 0.87;
  height: 2rem;
}

.text_app-store {
  color: #d4d4d4;
  font-size: 1rem;
}

.text_app-store.dark {
  color: #000;
}

.img_app-store-stars {
  opacity: 0.74;
  width: 6.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.img_app-store-stars.thank-you {
  width: 15rem;
}

.subtext_app-rating {
  color: #d3d3d3;
  font-size: 0.85rem;
  font-weight: 300;
}

.subtext_app-rating.font-black {
  color: #000;
}

.subtext_app-rating.font-darkgrey {
  color: #747474;
}

.container_app-rating {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.app-store-img_stars-copy {
  opacity: 0.74;
  width: 6.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.app-store-img_stars-copy.thank-you {
  width: 15rem;
}

.wrapper_app-rating {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  opacity: 0.75;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.container_app-store-item {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.subheader_landing-hero {
  color: #fff;
  text-align: left;
  font-size: 1.3rem;
  line-height: 1.95rem;
}

.subheader_landing-hero.steps {
  margin-left: 32px;
  margin-right: 32px;
}

.subheader_landing-hero.secondary {
  color: #7c7c7c;
  margin-top: 10px;
}

.subheader_landing-hero.small {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  line-height: 1rem;
}

.header_landing-hero {
  color: #fff;
  text-align: left;
  font-size: 4rem;
  line-height: 4.75rem;
}

.header_landing-hero.is-blue {
  color: var(--is-blue);
}

.header_landing-hero.left-align {
  text-align: left;
}

.header_landing-hero.left-align.cardpullover {
  width: 400px;
  font-size: 3.2rem;
}

.header_landing-hero.is-landing {
  text-align: left;
}

.list-item_landing {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.font_landing-feature-subheader {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
}

.wrapper_pricing-selector {
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
  display: flex;
}

.wrapper_plan {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  justify-content: center;
  width: 100%;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

.label_plan {
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 20px;
  width: 128px;
  padding: 6px 12px;
  font-weight: 500;
}

.label_plan.is-highlight {
  color: var(--ee-dark-blue);
  -webkit-text-stroke-color: var(--is-dark-blue);
  background-color: #fff;
  font-weight: 700;
}

.div-block-45 {
  border: 1px solid #000;
  width: 350px;
}

.container_plan-price {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
}

.container_pricing-plan {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.font_plan-price {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
}

.font_plan-price.is-highlight {
  color: #fff;
}

.font_plan-frequency {
  color: var(--is-grey-v2);
  line-height: 1.25rem;
}

.font_plan-frequency.is-highlight {
  color: var(--white);
}

.button_plan {
  border: 1px solid var(--is-dark-blue);
  background-color: var(--is-light-blue);
  color: var(--is-dark-blue);
  text-align: center;
  -webkit-text-stroke-color: var(--is-dark-blue);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: 500;
  display: block;
}

.button_plan:hover {
  background-color: var(--is-blue);
  color: var(--white);
}

.button_plan.has-subtext {
  margin-bottom: 1rem;
}

.button_plan.is-highlight {
  -webkit-text-stroke-color: var(--is-dark-blue);
}

.button_plan.is-highlight:hover {
  background-color: var(--is-blue);
  color: var(--white);
  -webkit-text-stroke-color: var(--white);
}

.div-block-48 {
  align-self: center;
}

.container_plan {
  border: 1px solid #7d7d7d;
  border-radius: 20px;
  width: 100%;
  max-width: 350px;
  padding: 1rem 1.25rem 2.5rem;
}

.container_plan.is-highlight {
  background-color: var(--ee-dark-blue);
  border-style: none;
}

.list_plan {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-flow: column;
  font-weight: 500;
  display: flex;
}

.list_plan.is-highlight {
  color: var(--white);
}

.list_plan-container {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.list_plan-icon {
  width: 24px;
}

.list_plan-item {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.list_plan-item.is-grey {
  color: var(--is-grey-v2);
}

.list_plan-text {
  margin-bottom: 0;
}

.font_plan-subtext {
  color: var(--is-grey-v2);
  font-weight: 500;
}

.font_plan-header {
  text-align: center;
  margin-top: 5rem;
}

.video_button {
  width: 300px;
}

.wrapper_video-button {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  justify-content: space-between;
  align-items: center;
  width: 265px;
  max-width: 265px;
  min-height: 50px;
  max-height: 50px;
  padding: 12px 20px 12px 4px;
  font-weight: 500;
  display: flex;
}

.text_video-button {
  color: var(--black);
  align-self: center;
  text-decoration: none;
}

.paragraph-2 {
  line-height: 1.6875rem;
}

.sublabel_plan {
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 0;
}

.sublabel_plan.is-highlight {
  color: var(--white);
}

.wrapper_buttons {
  grid-column-gap: 5%;
  grid-row-gap: 5%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  display: flex;
}

.image_play-button {
  width: 48px;
}

.div-block-49 {
  align-self: stretch;
}

.div-block-50 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-51 {
  background-color: red;
  border-radius: 20px;
  width: 300px;
  display: flex;
  box-shadow: 7px 8px 15px 7px #0003;
}

.link-block-3 {
  text-align: center;
  border-radius: 20px;
  width: 10rem;
  height: 3.5rem;
  padding-top: 12px;
}

.link-block-3._2 {
  background-color: #973232;
}

.wrapper_selector-buttons {
  background-color: var(--white);
  border-radius: 100px;
  width: 18.25rem;
  display: flex;
  box-shadow: 0 2px 10px 1px #0003;
}

.wrapper-content_realtime-hero {
  flex-flow: column;
  width: 65%;
  min-width: 600px;
  display: flex;
}

.container_realtime_footer {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  align-items: center;
  padding-top: 6.8rem;
  padding-bottom: 15.3rem;
  display: flex;
}

.textreceiptanimation {
  width: 80%;
}

.textreceiptanimation.absolute {
  opacity: 0;
  position: absolute;
}

.account-software {
  text-align: center;
  width: 100%;
  max-width: 1000px;
  margin-top: 56px;
  margin-bottom: -67px;
}

.accounting_logos {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: none;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .calc-breakdown_grid-faucet-repair {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.25fr;
  }

  .cc-banner_container {
    justify-content: center;
  }

  .image-28px {
    width: 28px;
    height: 28px;
  }

  .landing_padding-horizontal.is-hero.card-landing {
    max-height: 600px;
  }

  .landing_hero-content-wrapper {
    justify-content: center;
  }

  .card_full_screen {
    max-height: 700px;
  }

  .div-block-27 {
    flex-direction: column;
    justify-content: center;
  }

  .body-2 {
    background-color: #fefefe;
  }

  .hero_gif_container {
    max-width: 375px;
  }

  .featureimage.frame-shadow {
    max-height: 600px;
  }
}

@media screen and (min-width: 1440px) {
  .landing_button.card {
    width: 250px;
    margin-top: 20px;
    margin-bottom: 1px;
    font-size: 1.7rem;
    line-height: 2rem;
  }

  .droptext1 {
    font-size: 12px;
    line-height: 18px;
  }

  .platform-link {
    width: 100%;
  }

  .video-wrapper {
    inset: 0%;
  }

  .card_reviews.card {
    margin-top: -21px;
  }

  .card_h1.left-align.cardpullover {
    line-height: 3.5rem;
  }

  .landing_hero-container-2a.card-landing {
    justify-content: center;
    margin-top: 65px;
  }

  .card_hero_center {
    max-width: 1000px;
  }

  .proof_padding-vertical.is-landing {
    padding-top: 3rem;
  }

  .card_text_secondary {
    margin-top: 29px;
    font-size: 1.4rem;
  }

  .card_text {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .card_full_screen {
    min-height: 675px;
  }

  .image-57 {
    bottom: 12%;
  }

  .card_hero_left {
    max-width: 1000px;
  }

  .card_extra_bold.left-align.cardpullover {
    line-height: 3.5rem;
  }

  .card_hero_left_update {
    max-width: 800px;
  }

  .semitransparentwhite {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  .header_landing-hero.left-align.cardpullover {
    line-height: 3.5rem;
  }
}

@media screen and (max-width: 991px) {
  .nav-link {
    text-align: center;
  }

  .button.cm-secondary {
    margin-top: 5px;
  }

  .image-4 {
    max-width: 45%;
    margin-left: 48px;
  }

  .image-5 {
    max-width: 45%;
    padding-right: 48px;
  }

  .proof_profile-wrapper {
    align-items: stretch;
  }

  .proof_profile-wrapper.is-calc-cta {
    align-items: center;
  }

  .header-div {
    margin-left: 12px;
    margin-right: 12px;
  }

  .brand.w--current {
    align-items: center;
    display: flex;
  }

  .navbutton {
    text-align: center;
    display: block;
  }

  .nav-menu {
    display: flex;
  }

  .menu-button.w--open {
    background-color: #f7f8fa;
  }

  .icon {
    color: #1369e3;
  }

  .stackedcards {
    max-width: 35%;
  }

  .super-large-text {
    font-size: 120px;
    line-height: 125px;
  }

  .spacer {
    width: 2rem;
    height: 2rem;
  }

  .spacer.phone-only {
    display: none;
  }

  .whitetext {
    font-size: 16px;
  }

  .gridexpense {
    margin-bottom: 20px;
  }

  .cc-formblock {
    display: flex;
  }

  .cc-banner_buttondiv {
    flex-direction: column;
    align-items: stretch;
    display: flex;
  }

  .cc-banner_container {
    flex-direction: column;
    align-items: center;
  }

  .cc-banner_paragraph {
    text-align: left;
  }

  .nav-link {
    height: auto;
  }

  .nav-link.w--open {
    height: auto;
    margin-bottom: 10px;
  }

  .section_proof.is-light-grey {
    text-align: center;
  }

  .blog_padding-horizontal {
    margin-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .container-large {
    max-width: 71rem;
  }

  .landing_padding-vertical {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .landing_padding-vertical.is-hero {
    padding-top: 1.5rem;
  }

  .landing_hero-content {
    max-width: 60%;
  }

  .industry_header-2 {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }

  .industry_header-2.flex-center.industry {
    margin-bottom: 0.5rem;
  }

  .landing_feature-image.is-hero {
    min-width: 200px;
  }

  .landing_feature-image.hide-mobile {
    max-width: 35%;
  }

  .marquee_card {
    margin-top: 19px;
  }

  .landing_hero-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .faq_item.last {
    border: 1px #000;
  }

  .section_feature-m {
    display: flex;
  }

  .calc_content-container {
    display: none;
  }

  .calc_slider {
    height: 80vh;
  }

  .calc_form-question-header.is-title {
    display: block;
  }

  .total-deduction.is-range {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .button-slider-nav:hover {
    background-color: var(--is-blue);
  }

  .button-slider-nav:active {
    background-color: var(--hover-blue);
  }

  .calc_slider-wrapper {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .calc_form-input-wrapper {
    justify-content: center;
  }

  .calc-proof_grid {
    grid-template-columns: 1fr;
  }

  .calc_form-submit-button {
    font-size: 1rem;
  }

  .calc-result_edit-header {
    padding-bottom: 1rem;
  }

  .calc_form-quick-input-button {
    cursor: pointer;
    border-color: #1369e34d;
  }

  .calc-result_stars {
    max-height: 20px;
    margin-bottom: 5px;
  }

  .calc-proof_header {
    padding-top: 2rem;
  }

  .blog-header_bg-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .blog-header_wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .blog_cta-wrapper {
    width: 100%;
  }

  .price-table {
    padding-left: 10px;
    padding-right: 10px;
  }

  .price-table_cell.cc-callout.cc-last {
    padding-left: 0;
    padding-right: 0;
  }

  .blog_text-style-h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .free-tax_padding-horizontal {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .free-tax_deduction-wrapper {
    align-content: flex-start;
  }

  .nav-link-3 {
    height: auto;
    margin-bottom: 10px;
  }

  .nav-dropdown-accordion {
    margin-top: -20px;
    margin-bottom: 10px;
    box-shadow: 0 7px 8px -1px #0003;
  }

  .nav-dropdown-accordion.w--open {
    box-shadow: none;
    background-color: #0000;
    margin-top: -10px;
    padding-top: 0;
  }

  .show-mobile {
    display: block;
  }

  .nav-dropdown-div {
    grid-row-gap: 0px;
    box-shadow: none;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    width: 500px;
    position: relative;
    top: 0;
  }

  .nav-dropdown-div.faq {
    width: 500px;
    padding-bottom: 10px;
  }

  .ham-button {
    justify-content: center;
    align-items: center;
    padding-top: 12px;
  }

  .ham-icon {
    margin-top: 12px;
  }

  .nav-menu-3 {
    background-color: var(--card_blue);
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    padding: 20px 25px 100px;
    position: absolute;
  }

  .nav-login-container.show-desktop {
    display: none;
  }

  .nav-login-container.show-mobile {
    flex-direction: column;
    padding-bottom: 10px;
    display: flex;
  }

  .nav-login-container.show-mobile.is-industry {
    display: none;
  }

  .feature-hero_content-wrapper {
    width: 100%;
  }

  .feature-hero_container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .feature_padding-horizontal {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .feature_wrapper {
    flex-direction: column;
    align-items: center;
  }

  .feature_wrapper.is-reversed {
    flex-direction: column;
  }

  .feature_text-style-h2 {
    text-align: center;
  }

  .section-spacing-vertical {
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;
  }

  .feature-hero_label-style {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .feature-hero_label-div {
    align-self: auto;
    padding-top: 1rem;
  }

  .video {
    width: 100%;
  }

  .feature-proof_review-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .feature-proof_review-container {
    width: 100%;
  }

  .landing_padding-horizontal,
  .landing_padding-horizontal.is-hero,
  .proof-header,
  .faq_padding-horizontal {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .faq_padding-vertical {
    padding-top: 0;
    padding-bottom: 2rem;
  }

  .calc-container {
    padding: 0;
  }

  .calc-result_container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .industry_padding-horizontal {
    margin-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .industry_hero-container {
    padding-bottom: 5rem;
  }

  .proof_header-div {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .industry_banner-header-wrapper.landing {
    width: 100%;
  }

  .industry_banner-row-wrapper {
    min-width: 100%;
  }

  .industry_banner-container {
    padding-top: 0;
    padding-bottom: 7.5rem;
  }

  .industry_banner-container.landing {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .landing_banner-row-container {
    flex-direction: column;
  }

  .industry_padding-vertical.proof {
    padding-bottom: 1rem;
  }

  .container-largest {
    max-width: 71rem;
  }

  .landing_text-style-h1 {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }

  .landing_text-style-h1.flex-center.industry {
    margin-bottom: 0.5rem;
  }

  .feature-content-wrapper {
    height: 20rem;
  }

  .feature-list-wrapper {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .feature-list-track {
    margin-right: 4rem;
  }

  .feature-content-track {
    width: 20rem;
  }

  .section_showcase {
    display: none;
  }

  .showcase_header-text {
    padding-left: 0;
  }

  .showcase_header-text.new-font {
    font-size: 2rem;
    font-weight: 600;
  }

  .image-showcase-wrapper {
    height: 450px;
  }

  .default-padding-horizontal {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .blog_text-style-h3 {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .tab {
    text-align: left;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }

  .tab-container {
    flex: 0 auto;
  }

  .tab-grid {
    flex-direction: column;
  }

  .tab-image {
    width: 200px;
  }

  .tab-menu {
    justify-content: flex-start;
  }

  .card_h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .card_h1.left-align {
    width: 100%;
  }

  .card_h1.left-align.cardpullover {
    width: 300px;
    font-size: 2.6rem;
    line-height: 3.1rem;
  }

  .tabs-wrapper {
    margin-top: 0;
  }

  .showcase_padding-horizontal {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .showcase_padding-vertical {
    padding-top: 3rem;
  }

  .section_showcase-mobile {
    display: flex;
  }

  .showcase_item.last {
    border: 1px #000;
  }

  .showcase_item.is-last {
    padding-top: 5px;
  }

  .section_getting-started {
    display: flex;
  }

  .landing_hero-content-wrapper {
    width: 100%;
  }

  .landing_hero-container-2a {
    padding-top: 3rem;
    padding-bottom: 5rem;
  }

  .landing_hero-container-2a.landing {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .landing_hero-banner-wrapper {
    min-width: 100%;
  }

  .industry_padding-vertical-copy.proof {
    padding-bottom: 1rem;
  }

  .image-34 {
    max-width: 45%;
  }

  .card_feature {
    justify-content: flex-start;
    align-items: center;
  }

  .image-40 {
    display: none;
  }

  .card_smallfeatureimage {
    object-fit: contain;
    width: 100%;
    max-height: 35vh;
    margin-bottom: 30px;
    display: block;
    position: static;
  }

  .div-block-29 {
    width: 300px;
  }

  .image-45 {
    position: absolute;
  }

  .image-46 {
    display: block;
  }

  .cardmainexplainer {
    justify-content: space-around;
    align-items: center;
  }

  .div-block-30 {
    height: 400px;
  }

  .cardexplainertext {
    width: 360px;
    font-size: 2.4rem;
    line-height: 3rem;
  }

  .cardexplainertext.left-align {
    width: 100%;
  }

  .cardexplainertext.left-align.cardpullover {
    width: 300px;
    font-size: 2.6rem;
    line-height: 3.1rem;
  }

  .image-51 {
    width: 175px;
    top: 10px;
    left: 17px;
  }

  .arrow {
    width: 50%;
    margin-top: -8px;
    margin-bottom: -13px;
  }

  .image-57 {
    display: none;
  }

  .blog-text-style-h4 {
    font-size: 1.125rem;
  }

  .card_extra_bold {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .card_extra_bold.left-align {
    width: 100%;
  }

  .card_extra_bold.left-align.cardpullover {
    width: 300px;
    font-size: 2.6rem;
    line-height: 3.1rem;
  }

  .card_sub_header {
    font-size: 1.1rem;
    line-height: 1.55rem;
  }

  .card_hero_left_update {
    max-width: 500px;
  }

  .card_superbold {
    font-size: 3.5rem;
  }

  .semitransparentwhite {
    font-size: 1.1rem;
    line-height: 1.55rem;
  }

  .text-block-50 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .div-block-32 {
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 6rem;
  }

  .div-block-33 {
    width: 60%;
  }

  .div-block-34 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .app-button_row {
    justify-content: center;
  }

  .div-block-38 {
    flex-direction: column;
    align-items: center;
  }

  .landing_padding-vertical-copy {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .landing_padding-vertical-copy.is-hero {
    padding-top: 1.5rem;
  }

  .thank-you_hero {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .image-62 {
    transform: rotateX(0) rotateY(-180deg) rotateZ(15deg);
  }

  .div-block-39 {
    justify-content: center;
  }

  .text-block-53 {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .feature_content-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .bg-video_feature {
    width: 242px;
    height: 500px;
  }

  .wrapper_landing-hero {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .wrapper-content_landing-hero {
    max-width: 31.25rem;
  }

  .container_landing-hero {
    max-width: 71rem;
  }

  .wrapper_landing-feature {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .wrapper_landing-teams {
    flex-flow: column-reverse wrap-reverse;
  }

  .wrapper_landing-teams.for-teams {
    padding-bottom: 2rem;
  }

  .container_landing-teams-txt {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
  }

  .icon_landing-teams {
    height: 50px;
  }

  .font_landing-feature-h1 {
    margin-bottom: 1rem;
    font-size: 2.4rem;
    line-height: 3rem;
  }

  .font_landing-feature-h1.left-align {
    width: 100%;
  }

  .font_landing-feature-h1.left-align.cardpullover {
    width: 300px;
    font-size: 2.6rem;
    line-height: 3.1rem;
  }

  .nav-type-container {
    display: none;
  }

  .nav-type-container.is-plan {
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }

  .nav-bar-3 {
    height: 4rem;
  }

  .nav-link-4 {
    text-align: center;
    height: auto;
    margin-bottom: 10px;
    display: flex;
  }

  .nav-link-4.is-hidden {
    display: none;
  }

  .nav-button-v2-mobile.is-blue-fill {
    width: 5rem;
    height: 2rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .nav-button-v2-mobile.is-blue-fill.is-pricing {
    display: none;
  }

  .nav-mobile-container {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    align-items: center;
    display: flex;
  }

  .nav-link-4-v2,
  .nav-link-3-copy {
    height: auto;
    margin-bottom: 10px;
  }

  .header_landing-hero {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  .header_landing-hero.left-align {
    width: 100%;
  }

  .header_landing-hero.left-align.cardpullover {
    width: 300px;
    font-size: 2.6rem;
    line-height: 3.1rem;
  }

  .wrapper_plan {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .wrapper-content_realtime-hero {
    min-width: 400px;
    max-width: 31.25rem;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 1rem;
    line-height: 1.125rem;
  }

  h1 {
    font-size: 2rem;
    line-height: 3rem;
  }

  h2 {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }

  h5 {
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }

  h6,
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  ol {
    padding-left: 20px;
  }

  li {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .heading {
    min-width: auto;
    max-width: 100%;
    font-size: 36px;
    line-height: 40px;
  }

  .text-block {
    width: 500px;
    max-width: 90%;
  }

  .feature-section {
    padding: 40px 20px;
  }

  .flex-container {
    flex-direction: column;
  }

  .flex-container.spacebetween {
    justify-content: space-between;
    align-items: flex-start;
  }

  .hero {
    padding: 40px 20px;
  }

  .hero.is-landing {
    padding-left: 0;
    padding-right: 0;
  }

  .heading2 {
    width: 500px;
    max-width: 90%;
    font-size: 24px;
    line-height: 28px;
  }

  .div-block {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    max-width: 100%;
    display: block;
  }

  .row {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: row;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .row.space-around.vert-phone {
    flex-direction: column;
  }

  .featurediv {
    max-width: 100%;
  }

  .image-4 {
    order: -1;
    max-width: 80%;
  }

  .image-5 {
    max-width: 80%;
  }

  .landing_stars.five-star.is-mobile {
    max-width: 13rem;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
  }

  .landing_stars.five-star.is-mobile.industry-specific {
    margin-top: -0.1rem;
  }

  .landing_stars.is-hero {
    padding-top: 1rem;
  }

  .proof_profile-name {
    line-height: 1.5rem;
  }

  .proof_profile-wrapper {
    align-items: center;
  }

  .div-block-7 {
    max-width: 100%;
  }

  .brand.w--current {
    justify-content: center;
    align-items: flex-start;
  }

  .stackedcards {
    display: none;
  }

  .large-text-container {
    width: auto;
    min-width: 50%;
  }

  .div-block-13 {
    height: 50px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .feature-heading {
    width: 500px;
    max-width: 90%;
    font-size: 32px;
  }

  .feature-text-block {
    width: 500px;
    max-width: 90%;
  }

  .download-button._1-25x {
    width: 144px;
    height: 48px;
  }

  .spacer.phone-only {
    display: block;
  }

  .greytext.flex-center.industry {
    color: #666;
    font-size: 12px;
  }

  .greytext.text-style-light.industry-header {
    display: none;
    overflow: visible;
  }

  .adcontainer {
    flex-direction: column;
    align-items: center;
  }

  .blogadimage {
    max-width: 60%;
    margin-bottom: 10px;
  }

  .ctalogo {
    margin-top: 10px;
  }

  .adcontentdiv {
    padding-left: 0;
  }

  .calc-breakdown_grid-faucet-repair {
    object-fit: fill;
  }

  .cc-formblock {
    display: flex;
  }

  .cc-banner_paragraph {
    margin-right: 0;
  }

  .nav-button {
    margin-bottom: 1.5rem;
  }

  .nav-button.is-blue-fill {
    margin-bottom: 0;
  }

  .nav-button.is-blue-fill:hover {
    background-color: #283f5f;
    border-color: #283f5f;
  }

  .nav-link:hover {
    color: #1369e3;
    border-bottom: 1px solid #1369e3;
  }

  .nav-link.w--current {
    display: flex;
  }

  .main-wrapper.align-stretch {
    align-items: stretch;
  }

  .section_feature {
    background-color: #0000;
    flex-direction: column;
    align-items: center;
  }

  .section_feature.hide-mobile {
    display: none;
  }

  .container-large.flex-horizontal-align-center.industry {
    margin-bottom: 0;
  }

  .landing_padding-vertical.is-hero {
    align-items: center;
  }

  .landing_padding-vertical.is-hero.industry {
    padding-top: 3rem;
  }

  .landing_hero-content {
    max-width: 100%;
  }

  .industry_header-2 {
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 2.25rem;
    line-height: 3rem;
    display: flex;
  }

  .industry_header-2.flex-center.industry {
    margin-bottom: 9px;
  }

  .landing_text-style-p {
    justify-content: center;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .landing_text-style-p.is-subtext {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .landing_text-style-p.is-subtext.review-text {
    margin-top: -0.4rem;
  }

  .landing_text-style-p.is-hero {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .landing_button.is-mobile {
    margin-top: 1rem;
    margin-bottom: 3rem;
    display: flex;
  }

  .landing_button.is-landing-hero.yellow {
    margin-top: 24px;
  }

  .landing_app-container {
    grid-row-gap: 1rem;
  }

  .img_app-store._1-25x {
    width: 144px;
    height: 48px;
  }

  .landing_feature-image {
    width: 100%;
  }

  .landing_feature-image.hide-mobile {
    display: none;
  }

  .landing_text-style-h2 {
    text-align: center;
    justify-content: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .landing_text-style-h2.industry-specific {
    text-align: left;
  }

  .landing_text-style-h2.industry-specific.flex-center {
    text-align: center;
    margin-top: 0;
  }

  .landing_list-item-text,
  .landing_list-item {
    font-size: 1rem;
  }

  .landing_feature-item {
    grid-row-gap: 1rem;
    flex-direction: column;
    align-items: center;
  }

  .landing_feature-item.is-reversed-mobile {
    flex-direction: column-reverse;
  }

  .landing_hero-container {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section_free-tax-header {
    text-align: center;
    padding-bottom: 10rem;
  }

  .accordion-item.is-landing {
    display: inline-block;
  }

  .heading-style-h3.get-started {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .heading-style-h2 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .about_wrapper {
    grid-row-gap: 1rem;
    flex-direction: column;
  }

  .about_container {
    max-width: 100%;
  }

  .more-features_container {
    font-size: 2rem;
  }

  .section_free-calculator {
    margin-top: -10rem;
  }

  .calc_form-question-header.is-title {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .calc_form-question-header.is-sub-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .input_field {
    width: 6rem;
    height: 2.5rem;
  }

  .total-deduction.is-range {
    font-size: 1.75rem;
  }

  .calc_form-input-field-wrapper {
    align-items: center;
  }

  .button-slider-nav {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .calc-result_edit-wrapper {
    width: 100%;
    max-width: 100%;
  }

  .calc-result_edit-header {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .calc-result_wrapper {
    min-height: 500px;
  }

  .calc_form-quick-input-button {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .flex-center-horizontal {
    text-align: center;
  }

  .calc-result_proof-row {
    flex-wrap: wrap;
  }

  .calc-result_stars {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .calc-result_cta-header {
    line-height: 1.75rem;
  }

  .blog-header_wrapper {
    margin-bottom: 1rem;
  }

  .sitemap_item.hide-map {
    display: none;
  }

  .blog_find-savings-wrapper {
    grid-row-gap: 1rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }

  .text-size-large {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .text-size-medium {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .blog_find-content-container {
    align-items: center;
  }

  .blog_cta-find-deduction-container {
    grid-row-gap: 0.5rem;
    border-top: 1px solid var(--dim-grey-shadow);
    border-left-style: none;
    margin-left: 0;
    padding-top: 0.75rem;
    padding-left: 0;
  }

  .user-review-text,
  .user-review-subtext {
    line-height: 1.5rem;
  }

  .swiper.cc-price-table {
    overflow: hidden;
  }

  .swiper-slide.cc-price-table {
    height: 100%;
  }

  .price-table {
    flex-direction: row;
    margin-bottom: 0;
    display: none;
  }

  .price-table.vs {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
  }

  .price-table.is-mobile {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
  }

  .price-table_options {
    flex: 1;
    width: 50%;
  }

  .price-table_cell-last-accent-bg {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 0;
    width: 20px;
    height: 100.5%;
    display: none;
    inset: 0% -20px 0% auto;
  }

  .price-table_cell {
    width: 100%;
    min-height: 115px;
    font-size: 1.3rem;
  }

  .price-table_cell.cc-title {
    text-align: center;
    border-bottom-style: none;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  .price-table_cell.cc-title.vs {
    width: 100%;
    max-height: 100px;
  }

  .price-table_cell.cc-header {
    border-bottom-style: solid;
    border-right: 1px solid #49484a1a;
    border-top-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    align-items: flex-start;
    min-height: 115px;
  }

  .price-table_cell.cc-header.vs {
    width: 100%;
  }

  .price-table_cell.vs {
    width: 100%;
    max-height: 100px;
  }

  .price-table_cell.cc-callout {
    border-top: 1px solid #5950f1;
    border-bottom-color: #5950f1;
    border-left-style: none;
    border-right-style: none;
  }

  .price-table_cell.cc-callout.cc-header {
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-left-radius: 12px;
  }

  .price-table_cell.cc-callout.cc-last {
    border-right-style: solid;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .price-table_cell.cc-callout.vs {
    width: 100%;
  }

  .pricing-table_mobile-nav-btn {
    background-color: var(--is-blue);
  }

  .pricing-table_mobile-nav {
    z-index: 2;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    justify-content: space-between;
    width: 100%;
    display: flex;
    top: 30px;
  }

  .price-table_row {
    flex-direction: column;
  }

  .price-table_row.cc-header {
    width: 50%;
  }

  .swiper-wrapper.cc-price-table {
    flex-direction: row;
  }

  .cta-button-blog {
    display: none;
  }

  .free-tax_padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .free-calculator_grid {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .free-tax_deduction-wrapper {
    grid-row-gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .free-tax_article-content {
    align-items: center;
    padding: 0;
  }

  .free-tax_article-header {
    white-space: normal;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .free-tax_article-wrapper {
    flex-direction: column;
  }

  .free-tax_article-container {
    width: 100%;
  }

  .blog_article-faq {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .section_faq-header {
    text-align: center;
  }

  .nav-dropdown-div,
  .nav-dropdown-div.faq {
    width: 450px;
  }

  .nav-menu-3 {
    overflow: scroll;
  }

  .feature_demo-container {
    width: 250px;
  }

  .feature_padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section_features {
    background-color: #0000;
    flex-direction: column;
    align-items: center;
  }

  .feature_wrapper.is-reversed {
    flex-direction: column;
  }

  .feature_text-style-h2 {
    justify-content: center;
    max-width: 25rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
  }

  .feature_text-style-p {
    justify-content: center;
    max-width: 25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    padding-top: 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .feature_bullet-text {
    font-size: 1rem;
  }

  .hide-mobile {
    display: none;
  }

  .feature_list-wrapper {
    flex-direction: column;
    max-width: 25rem;
  }

  .div_landing-learn {
    justify-content: center;
    padding-bottom: 2rem;
  }

  .video-wrapper {
    display: none;
  }

  .video {
    padding: 5% 10%;
  }

  .section_feature-proof {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .landing_banner-row-text-2 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .industry_review-score-text {
    display: block;
  }

  .industry_review-container {
    flex-direction: column;
    display: flex;
  }

  .industry_hero-wrapper {
    text-align: center;
  }

  .landing_padding-horizontal,
  .landing_padding-horizontal.is-hero {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .landing_feature-content {
    width: 100%;
    max-width: 25rem;
  }

  .proof-header,
  .faq_padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .faq_padding-vertical {
    padding-top: 0;
    padding-bottom: 1rem;
  }

  .feature-proof_review-text,
  .feature-proof_review-subtext {
    line-height: 1.5rem;
  }

  .calc-result_container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .industry_hero-container {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }

  .card_reviews {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  .industry_text-style-p {
    justify-content: center;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .industry_text-style-p.is-subtext {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .industry_text-style-p.is-subtext.review-text {
    margin-top: -0.4rem;
  }

  .industry_text-style-p.is-hero {
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }

  .industry_button.is-mobile {
    margin-top: 1rem;
    margin-bottom: 3rem;
    display: flex;
  }

  .industry_review-score {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: row;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .industry_banner-header-wrapper {
    width: 60%;
  }

  .industry_text-style-h2 {
    justify-content: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .industry_text-style-h2.industry-specific.flex-center {
    text-align: center;
    margin-top: 0;
  }

  .section_feature-banner {
    background-color: #0000;
    flex-direction: column;
    align-items: center;
  }

  .section_feature-banner.hide-mobile {
    display: none;
  }

  .industry_list-item-text {
    font-size: 1rem;
  }

  .industry_padding-vertical.is-showcase {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .industry_pricing-content {
    width: 100%;
  }

  .industry_pricing-wrapper {
    grid-row-gap: 5rem;
    flex-direction: column-reverse;
  }

  .industry_pricing-select {
    grid-row-gap: 2.5rem;
    width: 100%;
  }

  .container-largest.flex-center.industry {
    margin-bottom: 0;
  }

  .landing_text-style-h1 {
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 2.3rem;
    line-height: 2.8rem;
    display: flex;
  }

  .landing_text-style-h1.flex-center.industry {
    margin-bottom: 9px;
  }

  .feature-content-wrapper {
    width: 35vh;
    height: 35vh;
  }

  .feature-list-track {
    width: auto;
    margin-right: auto;
  }

  .feature-content-track {
    opacity: 0;
    align-items: flex-end;
    width: 100%;
    height: auto;
    padding: 1.25rem;
    display: none;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
  }

  .industry_show-case-wrapper {
    flex-direction: column;
  }

  .showcase_item-header {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .div-block-15 {
    width: 100%;
  }

  .section_showcase {
    flex-direction: column;
    align-items: center;
  }

  .image-showcase-wrapper {
    width: 100%;
  }

  .section_pricing {
    flex-direction: column;
    align-items: center;
  }

  .section_pricing.hide-mobile {
    display: none;
  }

  .default-padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .accordion-item-3rem.is-landing {
    display: inline-block;
  }

  .get-started_header {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .tab {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tab-menu {
    flex-direction: column;
  }

  .card_h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .card_h1.left-align.cardpullover {
    width: 300px;
  }

  .showcase_padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .card_hero_center.blue {
    padding: 64px 16px;
  }

  .industry_padding-vertical-copy.is-showcase {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .div-block-23 {
    padding-bottom: 34px;
  }

  .card_step_container {
    margin-right: 12px;
  }

  .image-34 {
    max-width: 90%;
  }

  .card_main_body {
    padding-top: 71px;
  }

  .image-37 {
    padding-right: 0;
  }

  .card_feature {
    flex-direction: column;
  }

  .card_featuretext {
    line-height: 1.4rem;
  }

  .card_featuretext.center {
    text-align: center;
  }

  .div-block-26 {
    margin-right: 0;
  }

  .image-40 {
    display: none;
  }

  .card_smallfeatureimage {
    object-fit: contain;
    width: 80vw;
    max-width: 400px;
    max-height: 50vh;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .div-block-27 {
    margin-top: -101px;
    margin-bottom: -42px;
  }

  .calc-breakdown_grid-income-tax {
    object-fit: fill;
  }

  .div-block-29 {
    width: 90%;
  }

  .cardmainexplainer {
    flex-direction: column;
    margin-bottom: 53px;
  }

  .div-block-30 {
    width: 200px;
    margin-bottom: 43px;
    margin-left: 0;
    margin-right: 0;
  }

  .cardexplainertext {
    text-align: center;
    width: auto;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .cardexplainertext.left-align.cardpullover {
    width: 300px;
  }

  .cardexplainertext.top {
    display: none;
  }

  .cardexplainertext.bottom {
    display: block;
  }

  .image-50 {
    margin-left: -89px;
  }

  .image-51 {
    top: 8px;
    left: 16px;
  }

  .arrow {
    display: none;
  }

  .cardexplainersubtext {
    text-align: center;
    line-height: 1.4rem;
  }

  .cardexplainersubtext.center {
    text-align: center;
  }

  .cardexplainersubtext.top {
    display: none;
  }

  .cardexplainersubtext.bottom {
    display: block;
  }

  .card_hero_left.blue {
    padding: 64px 16px;
  }

  .card_extra_bold {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .card_extra_bold.left-align.cardpullover {
    width: 300px;
  }

  .card_hero_left_update {
    max-width: 400px;
  }

  .card_hero_left_update.blue {
    padding: 64px 16px;
  }

  .hero_gif_container {
    display: none;
  }

  .card_superbold {
    font-size: 2.6rem;
    line-height: 4rem;
  }

  .div-block-33 {
    width: 100%;
  }

  .div-block-35 {
    align-self: center;
  }

  .div-block-37 {
    width: 300px;
  }

  .landing_padding-vertical-copy.is-hero {
    align-items: center;
  }

  .landing_padding-vertical-copy.is-hero.industry {
    padding-top: 3rem;
  }

  .wrapper_landing-hero {
    height: 75vh;
    min-height: 500px;
  }

  .wrapper-content_landing-hero {
    width: 100%;
  }

  .container-img_landing-hero {
    display: none;
  }

  .container_landing-hero.flex-horizontal-align-center.industry {
    margin-bottom: 0;
  }

  .container_landing-teams-txt {
    align-items: center;
    width: 100%;
  }

  .container_landing-teams-img {
    width: 65%;
  }

  .list-wrapper_landing-teams {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }

  .container_landing-feature-img {
    width: 65%;
  }

  .item_landing-feature {
    flex-flow: column;
    margin-bottom: 3rem;
  }

  .item_landing-feature.reversed {
    flex-flow: column-reverse;
  }

  .img-arrow_landing {
    display: none;
  }

  .container_landing-teams {
    flex-flow: column-reverse;
  }

  .font_landing-feature-h1 {
    text-align: center;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .font_landing-feature-h1.left-align.cardpullover {
    width: 300px;
  }

  .font_landing-feature-h1.top {
    display: none;
  }

  .font_landing-feature-h1.bottom {
    display: block;
  }

  .font_landing-feature-subh1 {
    text-align: center;
    line-height: 1.4rem;
  }

  .font_landing-feature-subh1.center {
    text-align: center;
  }

  .font_landing-feature-subh1.top {
    display: none;
  }

  .font_landing-feature-subh1.bottom {
    display: block;
  }

  .container_landing-feature-txt {
    width: 100%;
  }

  .list-txt_landing-teams {
    font-size: 1.125rem;
  }

  .font_landing-teams-subtext {
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }

  .font_landing-teams-subtext.is-teams {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }

  .nav-button-v2 {
    margin-bottom: 1.5rem;
  }

  .nav-button-v2.is-blue-fill {
    margin-bottom: 0;
  }

  .nav-button-v2.is-blue-fill:hover {
    background-color: #283f5f;
    border-color: #283f5f;
  }

  .nav-button-v2-mobile {
    margin-bottom: 1.5rem;
  }

  .nav-button-v2-mobile.is-blue-fill {
    margin-bottom: 0;
  }

  .nav-button-v2-mobile.is-blue-fill:hover {
    background-color: #283f5f;
    border-color: #283f5f;
  }

  .nav-button-v2-mobile.is-blue-fill.is-pricing {
    display: block;
  }

  .flex-center-vertical {
    text-align: center;
  }

  .container_app-rating {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: row;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .container_app-store-item {
    flex-direction: column;
    display: flex;
  }

  .header_landing-hero {
    font-size: 2rem;
    line-height: 3rem;
  }

  .header_landing-hero.left-align.cardpullover {
    width: 300px;
  }

  .list-item_landing,
  .font_landing-feature-subheader {
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }

  .wrapper_buttons {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .wrapper-content_realtime-hero {
    width: 100%;
    min-width: auto;
    max-width: none;
  }

  .accounting_logo {
    max-width: 15%;
  }
}

@media screen and (max-width: 479px) {
  ul {
    padding-left: 20px;
  }

  .heading {
    font-size: 24px;
    line-height: 28px;
  }

  .text-block {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 20px;
  }

  .button.primary {
    text-align: center;
    width: 100%;
    max-width: none;
  }

  .button.inverse {
    text-align: center;
    width: 100%;
    margin-top: 16px;
  }

  .button.cm-secondary {
    background-color: #f7f8fa;
  }

  .button.cc-deny {
    width: 100px;
  }

  .feature-section {
    padding-left: 0;
    padding-right: 0;
  }

  .flex-container.spacebetween {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero.is-landing {
    margin-right: 0;
  }

  .heading2 {
    font-size: 20px;
    line-height: 24px;
  }

  .div-block {
    padding-left: 0;
    padding-right: 0;
    overflow: visible;
  }

  .row {
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    padding-top: 5px;
    padding-bottom: 2px;
  }

  .row.spaced-row.card {
    flex-direction: row;
    justify-content: center;
  }

  .row.space-around.vert-phone {
    flex-direction: column;
  }

  .text {
    font-size: 16px;
    line-height: 20px;
  }

  .image-4 {
    order: -1;
    max-width: 75%;
    margin-left: 0;
  }

  .image-5 {
    max-width: 75%;
    padding-right: 0;
  }

  .landing_stars.five-star.is-mobile.industry-specific {
    max-width: 65%;
  }

  .nav-menu {
    background-color: #f7f8fa;
    box-shadow: 1px 1px 3px #000;
  }

  .container-footer {
    width: 90%;
  }

  .lightgreytext.manage-cookies {
    padding-left: 0;
  }

  .lightgreytext.hyphen {
    padding-left: 3px;
    padding-right: 3px;
  }

  .lightgreytext.copyright {
    margin-top: 10px;
  }

  .stackedcards {
    display: none;
  }

  .super-large-text {
    font-size: 100px;
    line-height: 105px;
  }

  .subtext-large {
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    display: flex;
  }

  .large-text-container {
    width: 100%;
  }

  .div-block-13 {
    height: 32px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .feature-heading {
    font-size: 20px;
    line-height: 24px;
  }

  .feature-text-block {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 20px;
  }

  .stars {
    font-size: 24px;
    line-height: 24px;
  }

  .image-16 {
    max-width: 25%;
  }

  .modal {
    display: none;
  }

  .modal.modal-container {
    max-width: 90%;
  }

  .download-button {
    max-width: 40%;
  }

  .spacer.phone-only {
    display: block;
  }

  .whitetext {
    font-size: 14px;
  }

  .greytext {
    color: #acacac;
  }

  .greytext.flex-center.industry {
    margin-bottom: 32px;
  }

  .gridheading {
    margin-bottom: 0;
  }

  .adcontainer {
    padding-left: 12px;
    padding-right: 12px;
  }

  .blogadimage {
    max-width: 90%;
    overflow: hidden;
  }

  .ctalogo {
    width: 70%;
    max-width: 200px;
  }

  .image-26 {
    max-width: 70%;
    margin-top: 12px;
    margin-bottom: 4px;
  }

  .adh1 {
    font-size: 28px;
    line-height: 32px;
  }

  .adcontentdiv {
    padding-left: 0;
  }

  .cc-banner_emoji {
    margin-bottom: 1.5rem;
  }

  .cc-banner_button-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    display: flex;
    position: static;
  }

  .cc-formblock {
    display: flex;
  }

  .cc-form {
    background-color: #f7f8fa;
  }

  .checkbox-field {
    display: flex;
  }

  .cookie-preferences {
    display: none;
  }

  .cc-banner_paragraph {
    flex-wrap: wrap;
    padding-right: 0;
  }

  .div_footer-links {
    align-items: center;
  }

  .cc-footer {
    align-items: center;
    display: none;
  }

  .cc-banner_overlay {
    justify-content: flex-start;
    inset: 0%;
  }

  .cc-banner_label,
  .bold-text-3 {
    font-size: 16px;
  }

  .main-wrapper {
    align-items: center;
  }

  .section_feature {
    align-self: center;
    align-items: center;
  }

  .section_feature.is-light-blue {
    flex-direction: column;
  }

  .blog_padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .container-large {
    flex-direction: column;
  }

  .landing_padding-vertical {
    flex-direction: column-reverse;
  }

  .landing_padding-vertical.is-hero {
    flex-direction: column;
  }

  .landing_text-style-p.is-subtext.review-text {
    font-size: 0.9rem;
    line-height: 1.25rem;
  }

  .landing_text-style-p.is-hero.flex-center.industry {
    margin-bottom: 1.4rem;
  }

  .landing_button {
    font-size: 1rem;
  }

  .landing_button.card {
    max-width: 80%;
  }

  .landing_button.dark_card {
    width: 100%;
    max-width: 250px;
  }

  .img_app-store {
    width: 100px;
    height: 36px;
  }

  .landing_text-style-h2.industry-specific.flex-center {
    margin-bottom: 1.1rem;
  }

  .marquee_card {
    width: 20rem;
  }

  .div_row-footer {
    max-width: 100%;
  }

  .landing_feature-item.is-reversed-mobile {
    flex-direction: column-reverse;
    display: flex;
  }

  .faq_item {
    flex-wrap: wrap;
  }

  .accordion-item.is-open {
    position: static;
  }

  .footer-link {
    text-align: center;
    line-height: 1.25rem;
  }

  .cc-div_text {
    flex-direction: column;
  }

  .cc-div_buttons {
    grid-column-gap: 1.5rem;
  }

  .calc-result_total-wrapper {
    width: 100%;
  }

  .text-block_result-proof-date {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .calc_form-submit-button.is-calc-edit {
    z-index: 998;
  }

  .calc-result_edit-container {
    width: 100%;
    max-width: 100%;
  }

  .calc-result_wrapper {
    flex-direction: column;
  }

  .calc-result_cta-wrapper {
    width: 100%;
    max-width: 100%;
  }

  .line-break {
    border-bottom: 1px solid #000;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .img_result-proof-stars {
    height: 24px;
  }

  .blog_intro-container {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .blog_writeoff-item {
    text-align: center;
    flex-direction: column;
  }

  .section_sitemap {
    justify-content: center;
  }

  .sitemap_item.hide-map {
    display: none;
  }

  .blog_cta-wrapper {
    flex-direction: column;
  }

  .cta_image-wrapper {
    width: 85%;
    display: flex;
  }

  .cta_content-wrapper {
    width: 100%;
  }

  .blog_cta-button-div {
    line-height: 1rem;
  }

  .blog_find-content-container {
    text-align: left;
  }

  .spacer-small {
    width: 1.5rem;
  }

  .button-horizontal-wrapper {
    flex-direction: column;
  }

  .blog_user-review-wrapper {
    overflow: auto;
  }

  .price-table_cell {
    padding: 20px;
    font-size: 1rem;
  }

  .price-table_cell.cc-title {
    padding-top: 20px;
  }

  .price-table_cell.cc-header {
    text-align: left;
  }

  .price-table_cell.cc-callout {
    min-height: 110px;
  }

  .pricing-table_mobile-nav {
    width: 120%;
    margin-left: -10%;
  }

  .cta-button-blog {
    display: flex;
  }

  .free-calculator_card {
    align-content: stretch;
  }

  .free-calculator_grid {
    flex-direction: column;
  }

  .free-tax_article-content {
    width: 95%;
  }

  .blog_article-faq {
    margin-top: 0;
  }

  .nav-dropdown-div {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .nav-dropdown-div.faq {
    width: 100%;
  }

  .section_features {
    align-self: center;
    align-items: center;
  }

  .video {
    padding: 0%;
  }

  .feature_image-container-1 {
    flex: none;
  }

  .section_feature-proof {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .feature-proof_review-wrapper {
    overflow: auto;
  }

  .spaced-row {
    flex-direction: column;
  }

  .landing_banner-row-text-2 {
    font-size: 2rem;
    line-height: 3rem;
  }

  .industry_review-score-text {
    font-size: 1rem;
  }

  .app-store-image {
    height: 40px;
    max-height: 10vw;
  }

  .industry_review-container {
    flex-direction: column;
    justify-content: flex-start;
    max-width: 45%;
    display: flex;
  }

  .landing_padding-horizontal.is-hero.card-landing {
    min-height: 500px;
    padding-bottom: 20px;
  }

  .landing_padding-horizontal.is-hero.card-landing-dark {
    width: 100%;
    min-height: 500px;
    padding-bottom: 20px;
  }

  .calc-container,
  .calc-result_container {
    flex-direction: column;
  }

  .industry_padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .industry_hero-container {
    padding-bottom: 2.5rem;
  }

  .card_reviews {
    grid-row-gap: 1rem;
    flex-wrap: wrap;
  }

  .card_reviews.card {
    flex-wrap: nowrap;
    margin-top: 0;
  }

  .industry_text-style-p.is-subtext.review-text {
    font-size: 0.9rem;
    line-height: 1.25rem;
  }

  .industry_text-style-p.is-hero {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .industry_text-style-p.is-hero.flex-center.industry {
    margin-bottom: 1.4rem;
  }

  .industry_button {
    font-size: 1rem;
  }

  .industry_review-stars {
    width: 5rem;
    max-width: 70%;
  }

  .industry_review-score {
    max-width: 100%;
  }

  .industry_review-subtext {
    font-size: 0.8rem;
  }

  .industry_banner-header-wrapper {
    width: 90%;
  }

  .industry_text-style-h2.industry-specific.flex-center {
    margin-bottom: 1.1rem;
  }

  .industry_banner-row-wrapper {
    grid-column-gap: 1rem;
    align-items: flex-start;
  }

  .section_feature-banner {
    align-self: center;
    align-items: center;
  }

  .section_feature-banner.is-light-grey,
  .container-largest {
    flex-direction: column;
  }

  .landing_text-style-h1 {
    font-size: 2rem;
    line-height: 2.2rem;
  }

  .feature-content-wrapper {
    width: 25vh;
    height: 25vh;
  }

  .section_showcase,
  .section_pricing {
    align-self: center;
    align-items: center;
  }

  .section_pricing.is-light-blue {
    flex-direction: column;
  }

  .paragraph-wrapper {
    font-size: 18px;
  }

  .card_h1 {
    font-size: 2rem;
  }

  .card_h1.left-align.cardpullover {
    width: 100%;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .card_h1.bold {
    font-weight: 700;
  }

  .marquee_card-2 {
    width: 20rem;
  }

  .showcase_item {
    flex-wrap: wrap;
  }

  .landing_hero-content-wrapper {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-direction: column;
  }

  .landing_hero-content-wrapper.sticky {
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    padding-left: 8px;
    padding-right: 8px;
  }

  .landing_hero-content-wrapper.card-landing {
    margin-top: 20px;
  }

  .landing_hero-container-2a {
    grid-row-gap: 2.5rem;
  }

  .landing_hero-container-2a.card-landing {
    padding-top: 0;
    padding-bottom: 0;
  }

  .landing_hero-image-container {
    align-self: center;
  }

  .card_hero_center.sticky {
    justify-content: flex-start;
    align-items: center;
    top: 0;
  }

  .landing_hero-banner-wrapper {
    grid-column-gap: 1rem;
    align-items: flex-start;
  }

  .card_text {
    line-height: 1.4rem;
  }

  .card_text.steps,
  .card_text.left-align {
    text-align: left;
  }

  .div-block-23 {
    margin-top: -55px;
    padding-bottom: 0;
    padding-right: 0;
    overflow: hidden;
  }

  .card_step_container {
    flex-direction: row;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 12px;
  }

  .image-32 {
    max-width: 60%;
    height: auto;
    top: 45.375px;
    left: -16.93px;
  }

  .image-33 {
    width: 50%;
  }

  .image-34 {
    width: 300px;
    max-width: 100%;
    display: block;
    position: static;
  }

  .card_main_body {
    padding-top: 69px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .image-37 {
    width: 90%;
  }

  .card_full_screen {
    justify-content: center;
    height: auto;
    min-height: auto;
    margin-bottom: 24px;
    display: flex;
  }

  .card_steps {
    flex-direction: column;
    align-items: flex-start;
  }

  .image-38 {
    height: 32px;
  }

  .card_step_emoji {
    height: 48px;
  }

  .card_feature {
    margin-bottom: 120px;
  }

  .div-block-26 {
    margin-right: 0;
  }

  .div-block-27 {
    background-color: #fff;
    height: 70vh;
    min-height: 500px;
  }

  .div-block-29 {
    max-width: 100%;
  }

  .cardmainexplainer {
    margin-bottom: 120px;
  }

  .cardexplainertext {
    width: 100%;
    font-size: 2rem;
  }

  .cardexplainertext.left-align.cardpullover {
    width: 100%;
    font-size: 1.9rem;
  }

  .cardexplainertext.bold {
    font-weight: 700;
  }

  .cardexplainertext.bottom {
    display: block;
  }

  .image-50 {
    margin-left: -54px;
  }

  .container-item_landing-features {
    width: 100vw;
    padding-left: 12px;
    padding-right: 12px;
  }

  .link {
    color: #1469e3;
  }

  .card_hero_left.sticky {
    justify-content: flex-start;
    align-items: center;
    top: 0;
  }

  .card_extra_bold {
    margin-top: 2.85rem;
    font-size: 2rem;
  }

  .card_extra_bold.left-align.cardpullover {
    width: 100%;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .card_extra_bold.bold {
    font-weight: 700;
  }

  .card_sub_header {
    line-height: 1.6rem;
  }

  .card_sub_header.steps,
  .card_sub_header.left-align {
    text-align: left;
  }

  .card_hero_left_update.sticky {
    justify-content: flex-start;
    align-items: center;
    top: 0;
  }

  .reviews_text {
    font-size: 0.8rem;
  }

  .hero_gif_container {
    align-self: center;
  }

  .card_superbold {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .semitransparentwhite {
    line-height: 1.6rem;
  }

  .semitransparentwhite.steps,
  .semitransparentwhite.left-align {
    text-align: left;
  }

  .landing_padding-vertical-copy {
    flex-direction: column-reverse;
  }

  .landing_padding-vertical-copy.is-hero {
    flex-direction: column;
  }

  .wrapper_landing-hero.realtime {
    height: 91vh;
  }

  .container_landing-hero {
    flex-direction: column;
  }

  .wrapper_landing-feature {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .container_landing-teams-img,
  .container_landing-feature-img {
    width: 100%;
  }

  .item_landing-feature {
    margin-bottom: 120px;
  }

  .font_landing-feature-h1.left-align.cardpullover {
    width: 100%;
    font-size: 1.9rem;
  }

  .font_landing-feature-h1.bold {
    font-weight: 700;
  }

  .font_landing-feature-h1.bottom {
    display: block;
  }

  .container_landing-feature-txt {
    width: 100vw;
    padding-left: 12px;
    padding-right: 12px;
  }

  .nav-button-v2-mobile.is-blue-fill {
    left: 5%;
  }

  .img_app-store-logo {
    height: 40px;
    max-height: 10vw;
  }

  .img_app-store-stars {
    width: 5rem;
    max-width: 70%;
  }

  .subtext_app-rating {
    font-size: 0.8rem;
  }

  .container_app-rating {
    max-width: 100%;
  }

  .app-store-img_stars-copy {
    width: 5rem;
    max-width: 70%;
  }

  .container_app-store-item {
    flex-direction: column;
    justify-content: flex-start;
    max-width: 45%;
    display: flex;
  }

  .subheader_landing-hero.steps,
  .subheader_landing-hero.left-align {
    text-align: left;
  }

  .subheader_landing-hero.small {
    margin-bottom: 1.3rem;
    line-height: 1rem;
  }

  .header_landing-hero {
    margin-top: 2.85rem;
    font-size: 2rem;
  }

  .header_landing-hero.left-align.cardpullover {
    width: 100%;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .header_landing-hero.bold {
    font-weight: 700;
  }

  .wrapper_video-button {
    max-width: 220px;
    font-size: 0.85rem;
    line-height: 1rem;
  }

  .image-65 {
    flex: none;
  }

  .account-software {
    margin-top: 50px;
    margin-bottom: -25px;
  }

  .text-watch-demo {
    font-size: 0.75rem;
  }
}

#w-node-_6e118012-2c5a-46dc-5a27-776ad49efda3-024bb152,
#w-node-_702a6e07-8452-fe72-c363-1be44616cc87-024bb152,
#w-node-_2fe3837a-3863-ebf0-62cc-2b0320db48c2-024bb152,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad19b-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad19d-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad19f-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1a1-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1a3-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1a5-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1a7-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1a9-ad5b0f33,
#fed-gross-income-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1ab-ad5b0f33,
#fed-deduction-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1ad-ad5b0f33,
#fed-taxable-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1af-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1b1-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1b5-ad5b0f33,
#fed-tax-total-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1b9-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1bb-ad5b0f33,
#fica-gross-income-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1bd-ad5b0f33,
#fica-deduction-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1bf-ad5b0f33,
#fica-taxable-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1c1-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1c3-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1c7-ad5b0f33,
#fica-tax-total-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1cb-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1cd-ad5b0f33,
#state-gross-income-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1cf-ad5b0f33,
#state-deduction-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1d1-ad5b0f33,
#state-taxable-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1d3-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1d5-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1d9-ad5b0f33,
#state-tax-total-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1dd-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1df-ad5b0f33,
#self-gross-income-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1e1-ad5b0f33,
#self-deduction-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1e3-ad5b0f33,
#self-taxable-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1e5-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1e7-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1eb-ad5b0f33,
#self-tax-total-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1ef-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1f1-ad5b0f33,
#total-gross-income-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1f3-ad5b0f33,
#total-deduction-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1f5-ad5b0f33,
#total-taxable-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1f7-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1f9-ad5b0f33,
#w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1fb-ad5b0f33,
#tax-total-grid.w-node-_1c6b3c37-fc18-2218-25e4-064a2a5ad1ff-ad5b0f33,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec269-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec26b-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec26d-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec26f-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec271-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec273-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec275-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec277-55f45195,
#fed-gross-income-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec279-55f45195,
#fed-deduction-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec27b-55f45195,
#fed-taxable-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec27d-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec27f-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec283-55f45195,
#fed-tax-total-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec287-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec289-55f45195,
#fica-gross-income-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec28b-55f45195,
#fica-deduction-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec28d-55f45195,
#fica-taxable-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec28f-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec291-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec295-55f45195,
#fica-tax-total-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec299-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec29b-55f45195,
#state-gross-income-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec29d-55f45195,
#state-deduction-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec29f-55f45195,
#state-taxable-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2a1-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec2a3-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec2a7-55f45195,
#state-tax-total-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2ab-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec2ad-55f45195,
#self-gross-income-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2af-55f45195,
#self-deduction-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2b1-55f45195,
#self-taxable-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2b3-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec2b5-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec2b9-55f45195,
#self-tax-total-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2bd-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec2bf-55f45195,
#total-gross-income-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2c1-55f45195,
#total-deduction-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2c3-55f45195,
#total-taxable-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2c5-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec2c7-55f45195,
#w-node-a26e53b2-7c34-9869-d771-8c05117ec2c9-55f45195,
#tax-total-grid.w-node-a26e53b2-7c34-9869-d771-8c05117ec2cd-55f45195 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7741aba8-02af-94c3-2650-427c9c2686c7-e8078e63 {
  grid-area: 1 / 1 / 2 / 13;
}

#w-node-_7741aba8-02af-94c3-2650-427c9c2686c8-e8078e63 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_7741aba8-02af-94c3-2650-427c9c2686f1-e8078e63 {
  grid-area: 1 / 2 / 2 / 2;
}

#w-node-_619a8a9b-9938-364d-e221-08b11c3e2293-9eddfcd6,
#w-node-_619a8a9b-9938-364d-e221-08b11c3e22a0-9eddfcd6,
#w-node-_619a8a9b-9938-364d-e221-08b11c3e22ad-9eddfcd6,
#w-node-_619a8a9b-9938-364d-e221-08b11c3e22b9-9eddfcd6,
#w-node-_619a8a9b-9938-364d-e221-08b11c3e22bb-9eddfcd6,
#w-node-_619a8a9b-9938-364d-e221-08b11c3e22bd-9eddfcd6,
#w-node-_619a8a9b-9938-364d-e221-08b11c3e22bf-9eddfcd6,
#w-node-_619a8a9b-9938-364d-e221-08b11c3e22c1-9eddfcd6,
#w-node-_619a8a9b-9938-364d-e221-08b11c3e22c5-9eddfcd6,
#faucet-amount.w-node-_619a8a9b-9938-364d-e221-08b11c3e22c9-9eddfcd6,
#hourly-rate.w-node-_619a8a9b-9938-364d-e221-08b11c3e22cb-9eddfcd6,
#hour-amount.w-node-_619a8a9b-9938-364d-e221-08b11c3e22cd-9eddfcd6,
#labor-costs.w-node-_7d2f7714-3c18-a476-1137-923a4242b1f3-9eddfcd6,
#part-costs.w-node-efe87407-138b-220b-4862-e09519882320-9eddfcd6,
#cost-range2.w-node-_3d13168b-7856-bc0b-8c75-8bbfe1403a9e-9eddfcd6 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 991px) {
  #w-node-ffee8908-80bf-a96e-dffc-385b842a008e-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a009f-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a00b2-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a00c2-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a00d4-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a00f4-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a011c-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a012d-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a0140-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a0150-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a0162-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a0182-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a01aa-b097c35d,
  #w-node-ffee8908-80bf-a96e-dffc-385b842a0236-b097c35d {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_7741aba8-02af-94c3-2650-427c9c2686c7-e8078e63 {
    grid-column: 1 / 4;
  }

  #w-node-_7741aba8-02af-94c3-2650-427c9c2686c8-e8078e63 {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-_7741aba8-02af-94c3-2650-427c9c2686f1-e8078e63 {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-a28fe322-431e-62ef-22c8-fed4523edcf3-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523edd04-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523edd6e-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523edd7f-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523eddfc-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede0c-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede18-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede25-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede32-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede43-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede71-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede81-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede8d-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523ede9a-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523edea7-1fdefaf6,
  #w-node-a28fe322-431e-62ef-22c8-fed4523edeb8-1fdefaf6,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629484d-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629485e-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162948c8-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162948d9-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294956-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294966-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294972-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629497f-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629498c-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629499d-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162949cb-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162949db-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162949e7-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162949f4-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294a01-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294a12-220b4842,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629484d-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629485e-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162948c8-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162948d9-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294956-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294966-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294972-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629497f-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629498c-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df1629499d-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162949cb-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162949db-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162949e7-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df162949f4-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294a01-2986cb9d,
  #w-node-fbc1443f-8f18-ea2a-4d08-e3df16294a12-2986cb9d {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}
